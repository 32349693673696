import _ from 'lodash';
import React, { useState, useEffect } from 'react'



import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import { Container, Row } from 'react-bootstrap'
import skillOptions from '../../skillOptions';

import AboutMe from '../../components/AboutMe';
import Intro from '../../components/Intro';
import Skills from '../../components/Skills';
import Experience from '../../components/Experience';
import Education from '../../components/Education';
import Contact from '../../components/Contact';
import PreviewHeader from '../../components/PreviewHeader';
import './style.css';
import ApplicationsPreview from '../../components/ApplicationsPreview';
import ExperiencePreview from '../../components/ExperiencePreview';
import SkillsPreview from '../../components/SkillsPreview';
import AboutMePreview from '../../components/AboutMePreview';
import EducationPreview from '../../components/EducationPreview';
import ContactPreview from '../../components/ContactPreview';
import IntroPreview from '../../components/IntroPreview';
import IntroPreviewDemo from '../../components/IntroPreviewDemo';

export default function Preview({defaultConfig}) {

 const runAnimation = (id, offset, anime) => {

  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

  var controller = new ScrollMagic.Controller();
 
  var scene = new ScrollMagic.Scene({
   triggerElement: id,
   offset: offset,
   reverse: false
  })
  .setTween(id, 0.5, anime)
  .addTo(controller);

 }

 const [config, updateConfig] = useState(defaultConfig)

 useEffect(() => {

  // title and subtitles
 
 runAnimation('#skills-white-bar', -200, {width: '100%', opacity: 1})
 runAnimation('#applications-white-bar', -200, {width: '100%', opacity: 1})
 runAnimation('#education-white-bar', -200, {width: '100%', opacity: 1})
 runAnimation('#about-me-white-bar', -200, {width: '100%', opacity: 1})
 runAnimation('#contact-white-bar', -500, {width: '100%', opacity: 1})
 runAnimation('#experience-white-bar', -200, {width: '100%', opacity: 1})
 runAnimation('#skills-subtitle-slide-up', -200, {y: 0, opacity: 1})
 runAnimation('#experience-subtitle-slide-up', -200, {y: 0, opacity: 1})
 runAnimation('#education-subtitle-slide-up', -200, {y: 0, opacity: 1})
 runAnimation('#applications-subtitle-slide-up', -200, {y: 0, opacity: 1})
 runAnimation('#contact-subtitle-slide-up', -500, {y: 0, opacity: 1})
 
 
 // applications
 
 for (var i = 0; i < config.applications.length; i++) {
  runAnimation(`#application-${i}-title-slide-left`, -200, {opacity: 1, x: 0});
  runAnimation(`#application-${i}-desc-slide-right`, -200, {opacity: 1, x: 0});
 }
 
 
 
  _.each(config.experience, (job, index) => {
   const technologies = job.technologies_used
   let numberOfTechnologies = technologies.length;
   for (let i = 0; i < numberOfTechnologies; i++) {
    const id = `#technology-${i}-in-${index}`
    
 
    let anime = {opacity: 1, duration: 20, delay: i * .1}
 
    runAnimation(id, -200, anime)
  }
 
  })
 
 
 
 
 }, [])

 useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: 'instant'
  });
}, []);

 return (
  <Container fluid>
   <Row>
    <PreviewHeader />
    <div className='preview-header-you-are-currently-mobile'>
    You are currently previewing the Horizon template theme.
    </div>
    <IntroPreviewDemo config={config} />
    <div id='about-me-skills-gradient' style={{ background: `linear-gradient(transparent, ${config.backgrounds.about_me_skills_background_color})` }}>
     <AboutMePreview config={config} />
     <div id="skills" className="skills--scroll-to-point" />
     <SkillsPreview config={config} preview={true} />
    </div>
    <div id="experience" className="experience--scroll-to-point" />
    <ExperiencePreview config={config} skillOptions={skillOptions} preview={true} />
    <div id="applications" className="applications--scroll-to-point" />
    <ApplicationsPreview config={config} preview={true} />
    <div id="education" className="education--scroll-to-point" />
    <EducationPreview config={config} />
    <div id="contact" className="contact--scroll-to-point" />
    <ContactPreview config={config} />
   </Row>
  </Container>

 )
}