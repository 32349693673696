import React from 'react';
import './style.css'

export default function LandingDesc() {

  const desc1 = 'Make a lasting impression in the digital world. Devhorizons empowers you to showcase your skills and achievements seamlessly, providing you with the perfect canvas for your unique story.'

  return (
    <div className='landing-desc-fade'>
      {desc1}
    </div>
  )

}