import React from 'react';
import './style.css'

export default function LandingDesc() {

  const desc3 = 'In the fast-paced world of tech, first impressions matter. Our sleekly-designed templates are designed to help you make a striking first impression.'
  
  return (
    <div className='landing-desc-fade'>
      {desc3}
    </div>
  )

}