import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

export default function MenuNavBar({ activeTab, type, setTab, setMenuHeader, setShowTabletInput, col, laptop, showTabletInput, mobile }) {

  const updateTab = (tab, title) => {
    setTab(tab);
    setShowTabletInput(true)
    setMenuHeader(title);
  }

  return (
    <Col xs={col ? col : 2} style={{ padding: '0', position: 'relative', top: laptop && !showTabletInput ? '90px' : '0px' }}>
      {showTabletInput && (laptop || mobile) && 
        <div className={`menu-bar-button-container`} onClick={() => {
          setShowTabletInput(false)
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="grey" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z" />
            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
          </svg>
        </div>
      }

      {(mobile && !showTabletInput) ?
        <div className={`menu-bar-button-container-open-mobile`} onClick={() => setShowTabletInput(true)}>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="grey" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
      </div>
    :
    <div>
    <a href={`#intro-${type}`} className={`menu-bar-button-container${activeTab === 'intro' ? '-active' : ''}`} onClick={() => updateTab('intro', 'Edit Intro')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'intro' ? 'white' : 'grey'} class="bi bi-person-circle" viewBox="0 0 16 16">
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
    </svg>
  </a>
  <a href={`#skills-${type}`} className={`menu-bar-button-container${activeTab === 'skills' ? '-active' : ''}`} onClick={() => updateTab('skills', 'Add & Edit Skills')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'skills' ? 'white' : 'grey'} class="bi bi-terminal" viewBox="0 0 16 16">
      <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9zM3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z" />
      <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12z" />
    </svg>
  </a>
  <a href={`#experience-${type}`} className={`menu-bar-button-container${activeTab === 'experience' ? '-active' : ''}`} onClick={() => updateTab('experience', 'Add & Edit Experience')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'experience' ? 'white' : 'grey'} class="bi bi-briefcase" viewBox="0 0 16 16">
      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
    </svg>
  </a>
  <a href={`#applications-${type}`} className={`menu-bar-button-container${activeTab === 'applications' ? '-active' : ''}`} onClick={() => updateTab('applications', 'Add & Edit Applications')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'applications' ? 'white' : 'grey'} class="bi bi-window-stack" viewBox="0 0 16 16">
      <path d="M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10ZM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1Zm1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8H3Zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2h12Z" />
    </svg>
  </a>
  <a href={`#education-${type}`} className={`menu-bar-button-container${activeTab === 'education' ? '-active' : ''}`} onClick={() => updateTab('education', 'Edit Education')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'education' ? 'white' : 'grey'} class="bi bi-mortarboard" viewBox="0 0 16 16">
      <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
      <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
    </svg>
  </a>
  <a href={`#contact-${type}`} className={`menu-bar-button-container${activeTab === 'contact' ? '-active' : ''}`} onClick={() => updateTab('contact', 'Edit Contact Information')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'contact' ? 'white' : 'grey'} class="bi bi-envelope" viewBox="0 0 16 16">
      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
    </svg>
  </a>
  <div className={`menu-bar-button-container${activeTab === 'download' ? '-active' : ''}`} onClick={() => updateTab('download', 'Download Template & Config')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={activeTab === 'download' ? 'white' : 'grey'} class="bi bi-download" viewBox="0 0 16 16">
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
    </svg>
  </div>  
    </div>  
    }

    </Col>
  )
}