import React, {useState} from 'react';
import './style.css';

export default function Header({ config, isMobile, isTablet, type }) {

  const [showMenu, setShowMenu] = useState(false);

  return (
  
      <div  id="header" className={`${(isMobile || isTablet) && 'header--container-mobile'} ${!isMobile && !isTablet && 'header--container col-8'}`}>
        <div className="header--name">
          {config.text.header_first_name} {config.text.header_last_name} - {config.text.header_position}
        </div>

        {(isMobile || isTablet) &&
         <div id="hamburger" onClick={() => setShowMenu(!showMenu)} className="header--submenu-hamburger">
         <div className="header--submenu-hamburger-slice"></div>
         <div className="header--submenu-hamburger-slice"></div>
         <div className="header--submenu-hamburger-slice"></div>
       </div>         
        }


          {(!isMobile && !isTablet) &&         <div className="header--option-container">
          <a href="#skills-laptop" className="header--option">
            {config.text.header_skills_title}
          </a>
          <a href="#experience-laptop" className="header--option">
          {config.text.header_experience_title}
          </a>
          <a href="#applications-laptop" className="header--option">
          {config.text.header_applications_title}
          </a>
          <a href="#education-laptop" className="header--option">
          {config.text.header_education_title}
          </a>
          <a href="#contact-laptop" className="header--option">
          {config.text.header_contact_title}
          </a>
        </div>}

        {(isMobile || isTablet) && showMenu && 
        <div id="submenu" className="d-flex header--submenu-container">
        <a href={`#skills-${type}`} className="header--submenu-option">
        {config.text.mobile_menu_skills_title}
        </a>
        <a href={`#experience-${type}`} className="header--submenu-option">
        {config.text.mobile_menu_experience_title}
        </a>
        <a href={`#applications-${type}`} className="header--submenu-option">
        {config.text.mobile_menu_applications_title}
        </a>
        <a href={`#education-${type}`} className="header--submenu-option">
        {config.text.mobile_menu_education_title}
        </a>
        <a href={`#contact-${type}`} className="header--submenu-option">
        {config.text.mobile_menu_contact_title}
        </a>
      </div>        
        }

      </div>
    

  )
}