import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { SketchPicker } from 'react-color'
import './style.css'

export default function ContactInput({config,changeBackgrounds, changeContact, changeText}) {

  const [openContactBackgroundColor, setOpenContactBackgroundColor] = useState(false);

  return (
    <Card className='input-flash'>
      <Card.Header>
        Edit Contact Information
      </Card.Header>
      <Card.Body>
      <div className='config-input-container mb-3'>

<TextField
          label='Contact Description'
          style={{width: '100%'}}
          value={config.text.contact_subtitle}
          onChange={e => changeText({ contact_subtitle: e.target.value })}
          />

        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact Background Color'
          style={{width: '100%'}}
          value={config.backgrounds.contact_background_color}
          onChange={e => changeBackgrounds({ contact_background_color: e.target.value })}
          />
          
          <div className='color-collapse-container'>
        <div className='color-collapse' style={{ backgroundColor: config.backgrounds.contact_background_color }} onClick={() => {
          setOpenContactBackgroundColor(!openContactBackgroundColor)
        }}>
        </div>
      </div>
        </div>
        <div className={openContactBackgroundColor ? 'contact--color-container' : 'color-container-hide'}>
        <SketchPicker color={config.backgrounds.contact_background_color} onChange={(e) => {
          changeBackgrounds({ contact_background_color: e.hex })
        }} />
      </div>
        <div className='config-input-container mb-3'>

        <TextField
          label='Contact Email'
          style={{width: '100%'}}
          value={config.contact.contact_email}
          onChange={e => changeContact({ contact_email: e.target.value })}
          />

        </div>
        <div className='config-input-container mb-3'>


        <TextField
          label='Contact Phone'
          style={{width: '100%'}}
          value={config.contact.contact_phone}
          onChange={e => changeContact({ contact_phone: e.target.value })}
          />


        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #1 Label'
          style={{width: '100%'}}
          value={config.contact.contact_link_1_label}
          onChange={e => changeContact({ contact_link_1_label: e.target.value })}
          />
        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #1 Link'
          style={{width: '100%'}}
          value={config.contact.contact_link_1}
          onChange={e => changeContact({ contact_link_1: e.target.value })}
          />
        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #2 Label'
          style={{width: '100%'}}
          value={config.contact.contact_link_2_label}
          onChange={e => changeContact({ contact_link_2_label: e.target.value })}
          />
        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #2 Link'
          style={{width: '100%'}}
          value={config.contact.contact_link_2}
          onChange={e => changeContact({ contact_link_2: e.target.value })}
          />
        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #3 Label'
          style={{width: '100%'}}
          value={config.contact.contact_link_3_label}
          onChange={e => changeContact({ contact_link_3_label: e.target.value })}
          />
        </div>
        <div className='config-input-container mb-3'>
        <TextField
          label='Contact URL #3 Link'
          style={{width: '100%'}}
          value={config.contact.contact_link_3}
          onChange={e => changeContact({ contact_link_3: e.target.value })}
          />
        </div>
      </Card.Body>
  </Card>
  )
}