import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';

export default function Steps() {

  return (
    <Container fluid className='steps-container'>

      <div className='steps-mobile'>
      <div className='steps-box-1-mobile'>
              Kickstart your tech portfolio in three simple steps
            </div>

            <div className='steps-box-2'>
            <div className='step-row'>
              <div className='white-ball'>1</div>
              <div style={{marginBottom: '15px'}}>
              Download a template
              </div>
              </div>
              <div className='step-row'>
              <div className='white-ball'>2</div>
              <div style={{marginBottom: '15px'}}>
              Build and design using the Portfolio Builder. When you're finished, export the config.json and image files.
              </div>
              </div>
              <div className='step-row'>
              <div className='white-ball'>3</div>
              <div style={{marginBottom: '15px'}}>
              Import your config.json and image files into your template and host the website.
              </div>
              </div>
              </div>
            
      </div>

<Row className='steps-laptop' style={{height: '75px'}}>
  <Col xs={3} md={1} lg={2} xl={3} />
  <Col xs={6} md={10} lg={8} xl={6} style={{borderBottom: '1px solid white'}} />
  <Col xs={3} md={1} lg={2} xl={3} />
</Row>

      <Row className='nopadding steps-laptop' style={{height: '100%', width: '100%'}}>
        <Col xs={3} md={1} lg={3} className='nopadding' />
        <Col xs={3} md={5} lg={3} className='nopadding'>
            <div className='steps-box-1'>
              <div>
              Kickstart your tech portfolio
              </div>
              <div>
              in three simple steps
              </div>
            </div>
        </Col>
        <Col md={5} xs={3} lg={3} className='nopadding'>
        <div className='steps-box-2'>
            <div className='step-row'>
              <div className='white-ball'>1</div>
              <div style={{marginBottom: '15px'}}>
              Download a template
              </div>
              </div>
              <div className='step-row'>
              <div className='white-ball'>2</div>
              <div style={{marginBottom: '15px'}}>
              Build and design using the Portfolio Builder. When you're finished, export the config.json and image files.
              </div>
              </div>
              <div className='step-row'>
              <div className='white-ball'>3</div>
              <div style={{marginBottom: '15px'}}>
              Import your config.json and image files into your template and host the website.
              </div>
              </div>
              </div>
          </Col>
      </Row>

      <Row className='steps-laptop' style={{height: '75px'}}>
  <Col xs={3} md={1} lg={2} xl={3} />
  <Col xs={6} md={10} lg={8} xl={6} style={{borderTop: '1px solid white'}} />
  <Col xs={3} md={1} lg={2} xl={3} />
</Row>


 
    </Container>
  )
}