import React from 'react';
import {Row, Col} from 'react-bootstrap'
import './style.css';


export default function Contact({config, isMobile, isTablet}) {
  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 0;
    mainWidth = 12
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }

  return (


    <Row className='contact--container nopadding' style={{background: `${config.backgrounds.contact_background_color}`}}>
<Col xx={sideWidths} />
        <Col xs={mainWidth} className='contact--box' style={{margin: '150px 0 150px 0'}}>
          <h1 id='contact'>
            {config.text.header_contact_title}
          </h1>
          <div id="contact-white-bar" className="thick-white-bar-off">
          </div>
          <h3 className="vertical-slide-animation" id="contact-subtitle-slide-up">
            {config.text.contact_subtitle}
          </h3>
        <div className="contact--card">
          <div className="contact--contact-method">
            {config.contact.contact_email}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_phone}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_1_label}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_2_label}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_3_label}
          </div>
        </div>
      </Col>


      <Col xx={sideWidths} />
    </Row>




  )
}

