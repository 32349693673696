import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './style.css';

export default function BuilderIntroHeader({}) {

  const [menu, openMenu] = useState(true)

 return (
  <Container fluid className='builder-intro-header-container'>
   <Row style={{height: '100%'}}>
    <Col xs={1} md={1} lg={2} xl={3} />
  <Col className='builder-intro-header-middle' xs={10} md={10} lg={8} xl={6}>
  <div>
  <Link to='/'>
    <div className='devhorizon-logo-white'>
    
    </div>
    </Link>
  </div>
  <div className='builder-intro-header-left-header'>
    <Link to='/web-developer-portfolio/builder/horizon'>
    <div className='builder-intro-header-menu-option'>
   Portfolio Builder
  </div>
    </Link>
  <a href='#templates'>
  <div className='builder-intro-header-menu-option'>
   Templates
  </div>
  </a>
  <a href='#pricing'>
  <div className='builder-intro-header-menu-option'>
   Pricing
  </div>
  </a>
  <a href='#aboutus'>
  <div className='builder-intro-header-menu-option'>
   About
  </div>
    </a>
  </div>
  <div className='landing-mobile-menu-burger-container' onClick={() => openMenu(!menu)}>
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
      </div>
      {menu ? null :
        <div className='builder-intro-mobile-menu'>
          <Link to='/web-developer-portfolio/builder/horizon'>
          <div className='builder-intro-mobile-menu-option'>
            Portfolio Builder
          </div>
          </Link>
          <a href='#templates'>
          <div className='builder-intro-mobile-menu-option'>
            Templates
          </div>
            </a>
            <a href='#pricing'>
            <div className='builder-intro-mobile-menu-option'>
            Pricing
          </div>
              </a>
              <a href='#aboutus'>
              <div className='builder-intro-mobile-menu-option'>
            About
          </div>
                </a>
        </div>}
  </Col>
    <Col xs={1} md={1} lg={2} xl={3} />
   </Row>
   </Container>
 )

}