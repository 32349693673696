import React from 'react';
import { Row, Col } from 'react-bootstrap';

import './style.css';

export default function Skills({ config, isMobile, isTablet }) {

      // label: 'CSS',
    // value: 'CSS',
    // iconSrc: null,
    // type: 'font',
    // fontHref: 'devicon-css3-plain',
    // fontColor: '#FFFFFF',
    // labelColor: '#FFFFFF',
    // backgroundColor: '#3D8FC6',
    // imgUrl: null,
    // isSvg: true,
    // borderColor: 'white'
    

  const renderSkills = () => {
    return config.skills.map((skill, id) => {
      const { title, color } = skill
        return (
        <div class="skills--skills-box">
        <div class="skills--skill-tab" style={{backgroundColor: color}}></div>
        <div class="skills--skill-tab-2" style={{backgroundColor: color}}></div>
        <div class="skills--skill-block">
          {title}
        </div>
        </div>
        )
    })
  }

  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 1;
    mainWidth = 10
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }



  return (
    <Row className="skills--container nopadding" id='skills-trigger'>
      <Row className='nopadding'>
        <Col xs={sideWidths} />
        <Col xs={mainWidth} className='half-width-black-banner nopadding'>
        <h1>
      {config.text.header_skills_title}
      </h1>
      <div className="thick-white-bar-off" id='skills-white-bar'>
      </div>
      <h3 className="vertical-slide-animation" id="skills-subtitle-slide-up">
            {config.text.skills_subtitle}
          </h3>
        </Col>
        <Col xs={sideWidths} />
      </Row>
      <Row className="mt-5" style={{width: '100%'}}>
      <Col xs={sideWidths} />
        <Col xs={mainWidth}>
            <div className="skills--block-container">
              {renderSkills()}
          </div>
        </Col>
        <Col xs={sideWidths} />
      </Row>
    <Col xs={6}>
    </Col>
    <Col xs={6}>
    </Col>
    <div style={{marginBottom: '150px'}} />
  </Row>



  )
}

