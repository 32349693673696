import React, {useState, useEffect} from 'react'
import './style.css'

const TypeWriter1 = () => {


  let text = 'Land'
  let delay = 100

  const [currentText, setCurrentText] = useState(' ');
  const [currentIndex, setCurrentIndex] = useState(0);

  const [delay2, setDelay2] = useState(800)
  const [delayIsSet, setDelayIsSet] = useState(false)



  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  let text2 = 'the job.'

  const [currentText2, setCurrentText2] = useState('');
  const [currentIndex2, setCurrentIndex2] = useState(0);

  useEffect(() => {

    if (currentIndex2 < text2.length) {
      const timeout2 = setTimeout(() => {
        setCurrentText2((prevText) => prevText + text2[currentIndex2]);
        setCurrentIndex2((prevIndex) => prevIndex + 1);
        if (!delayIsSet) {
          setDelayIsSet(true)
          setDelay2(100)
        }
      }, delay2 );

      return () => clearTimeout(timeout2);
    }
  }, [currentIndex2, delay2, text2]);

  return (
    <div className='landing-intro-container'>
    <div className='landing-intro-box typewriter-title-text'>
    {currentText}
    </div>
    <div className='landing-intro-box typewriter-title-text' style={{display: 'flex', justifyContent: 'flex-start'}}>
    {currentText2}
    <div className='fade-in-line'></div>
    </div>
  </div>
  )
}

export default TypeWriter1;