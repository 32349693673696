import React from 'react';

import LandingHeader from '../../components/LandingHeader';
import AboutUsPage from '../aboutUsPage';

export default function AboutUsView({}) {
 return (
  <div>
      <LandingHeader />
      <AboutUsPage />
  </div>
 )
}