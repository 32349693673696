import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import PurchaseModal from '../../components/PurchaseModal';
import './style.css';

const BuilderIntro = () => {
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  return (
    <Container fluid className='builder-intro-container'>
           <PurchaseModal purchaseModalClose={() => setPurchaseModalOpen(false)} purchaseModalOpen={purchaseModalOpen} />
      <div className='builder-intro-jump-to-point' id='templates' />
      <Row className='nopadding' style={{ width: '100%' }}>
        <Col xs={0} md={1} lg={2} xl={3} />
        <Col xs={12} md={10} lg={8} xl={6} className='nopadding builder-intro-middle'>
          <div className='builder-intro-header'>
            Featured Template
          </div>
          <div className='builder-intro-box'>
            <div className='builder-intro-horizon-text'>
              Horizon
            </div>
            <div className='builder-intro-white-bar' />
            <div className='builder-intro-template-desc'>
              Sleeky designed. Dynamically animated. Horizon is your statement of excellence, innovation and professionalism. Showcase your coding prowess with elegant sections dedicated to skills, projects, and career highlights. Don't just showcase your work; make it unforgettable
            </div>
                {/* Desktop view */}
            <div className='builder-intro-preview-container'>
              <div className='builder-intro-avatar-1'></div>
              <div className='builder-intro-text-container'>
                <div>
                  <div className='builder-intro-text-title'>
                    John - Tech Lead
                  </div>
                  <div className='builder-intro-text-desc'>
                  Passionate freelance web developer with a specialization in React.js Loves cats!
                  </div>
                </div>
                <div className='builder--intro-button-container'>
                  <Link to='/web-developer-portfolio/horizon/john'>
                    <Button variant='primary'>See Preview</Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className='builder-intro-preview-container' style={{marginTop: '15px'}}>
              <div className='builder-intro-avatar-2'></div>
              <div className='builder-intro-text-container'>
                <div>
                  <div className='builder-intro-text-title'>
                    Jessica - Software Engineer
                  </div>
                  <div className='builder-intro-text-desc'>
                    Multifaceted professional experienced in engineering impactful solutions. Loves coffee!
                  </div>
                </div>
                <div className='builder--intro-button-container'>
                  <Link to='/web-developer-portfolio/horizon/jessica'>
                    <Button variant='primary'>See Preview</Button>
                  </Link>
                </div>
              </div>
            </div>
                {/* End desktop view */}
            <div className='builder-intro-start-building-container'>
              <Button variant='success' onClick={() => setPurchaseModalOpen(true)}>Purchase Template</Button>
              <Link to='/web-developer-portfolio/builder/horizon'>
              <Button style={{marginLeft: '15px'}} variant='primary'>Start Building</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={0} md={1} lg={2} xl={3} />
      </Row>
    </Container>
  );
};

export default BuilderIntro;
