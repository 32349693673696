import React, { useState, useRef } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css'

export default function DownloadInput({ handleDownload, handleFileChange}) {

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

 return (
  <div style={{paddingBottom: '150px'}}>
<Card className='input-flash'>
                  <Card.Header>
                    Three steps to finish!
                  </Card.Header>
                  <Card.Body>
            
    1. When you are finished designing your portfolio, click the button below to download the following:
    <div style={{marginTop: '15px'}}>
    <span style={{ fontWeight: 'bold' }}>&#x2022; config.json</span> file
    </div>
    <div>
    &#x2022; A folder containing background and avatar images
    </div>
    <div style={{marginTop: '15px'}}>
  2. Then, at the root level of the Horizon template, replace the <span style={{ fontWeight: 'bold' }}>config.json</span> with your newly downloaded one.</div>
  <div style={{marginTop: '15px'}}>
  3. Finally, replace the images found in <span style={{ fontWeight: 'bold' }}>"/assets/images"</span> folder with the newly downloaded images. You are ready to host!
  </div>
  <div className='mb-3 download-button-container'>
  <Button onClick={handleDownload} variant='primary'>Download Config & Images</Button>
    </div>

    <div style={{marginTop: '15px'}}>
      We recommend <a href='http://www.hostgator.com' style={{ color: '#3366CC' }} target="_blank" rel="noopener noreferrer">HostGator.com</a> for quick and easy web hosting.
    </div>
   
    







                  </Card.Body>
                </Card> 


     <Card style={{marginTop: '15px'}}>
     <Card.Header>
                    Purchase Horizon Template
                  </Card.Header>
      <Card.Body>
      <Form.Label style={{ marginTop: '15px' }}>
    You can purchase the Horizon portfolio template by clicking the button below.
   </Form.Label>

   <div className='mb-3 download-button-container'>
    <a href='https://we.tl/p-SWqMrBRud4' target="_blank" rel="noopener noreferrer">
    <Button variant='success'>Purchase Horizon Template ($12.99)</Button>
    </a>

   </div>
   <div style={{marginTop: '15px', color: 'grey', textAlign: 'center'}}>You will be redirected to Wetransfer to checkout and download.</div>
      </Card.Body>
     </Card>

     <Card style={{marginTop: '15px'}}>
                <Card.Body>
                <Form.Label style={{ marginTop: '15px' }}>
    If you already have a Devhorizons portfolio and want to make changes to it, upload your <span style={{ fontWeight: 'bold' }}>config.json</span> file, make your desired edits and then redownload.
   </Form.Label>

   <div className='mb-3 download-button-container'>
            <Button variant="secondary" style={{ marginRight: '15px' }} onClick={handleButtonClick}>
              <div className='d-flex justify-content-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-upload" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                </svg>
                <div style={{ marginLeft: '10px' }}>
                  Upload Config.json
                </div>
              </div>
              <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            </Button>
   </div>
                </Card.Body>
                </Card>

  </div>
 )
}