import { TextField } from '@mui/material';
import { Card } from 'react-bootstrap';


export default function EducationInput({ config, changeConfig, changeEducation, changeBackgrounds }) {
  return (
    <Card className='input-flash'>
    <Card.Header>
      Edit Education
    </Card.Header>
    <Card.Body>
      <div className='config-input-container mb-3'>
        <TextField
          label='Educational Institution Name'
          style={{ width: '100%' }}
          value={config.education.education_institution_name}
          onChange={e => changeEducation({ education_institution_name: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Educational Institution Location'
          style={{ width: '100%' }}
          value={config.education.education_institution_location}
          onChange={e => changeEducation({ education_institution_location: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Educational Institution Description'
          style={{ width: '100%' }}
          value={config.education.education_institution_desc}
          onChange={e => changeEducation({ education_institution_desc: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='View Certification Button Title'
          style={{ width: '100%' }}
          value={config.education.education_button_title}
          onChange={e => changeEducation({ education_button_title: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='View Certification Button URL'
          style={{ width: '100%' }}
          value={config.education.education_button_url}
          onChange={e => changeEducation({ education_button_url: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Educational Institution Logo URL'
          style={{ width: '100%' }}
          value={config.backgrounds.education_institution_avatar_image_url}
          onChange={e => changeBackgrounds({ education_institution_avatar_image_url: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Education Background Image URL'
          style={{ width: '100%' }}
          value={config.backgrounds.education_background_image_url}
          onChange={e => changeBackgrounds({ education_background_image_url: e.target.value })}
        />
      </div>
      </Card.Body>
    </Card>
  )
}