import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css'

export default function SlidingHeader() {

 const [isVisible, setIsVisible] = useState(false); // State to manage visibility

  const [menu, openMenu] = useState(true)

  useEffect(() => {
   const handleScroll = () => {
       const currentScrollPos = window.scrollY;
       const shouldBeVisible = currentScrollPos > 75;

       setIsVisible(shouldBeVisible);
   };

   window.addEventListener('scroll', handleScroll);

   return () => {
       window.removeEventListener('scroll', handleScroll);
   };
}, []);

  return (
    <div className={` landing-header-container ${isVisible ? 'header-visible' : 'header-hidden'}`}>
  <div>
  <Link to='/'>
    <div className='devhorizon-logo-white'>
    
    </div>
    </Link>
  </div>
      <div className='landing-menu-container'>
      <Link to='/web-developer-portfolio/builder/horizon'>
        <div className='landing-menu-item'>Portfolio Builder</div>
        </Link>
        <a href='#templates'>
        <div className='landing-menu-item'>Templates</div>
        </a>
        <a href='#pricing'>
        <div className='landing-menu-item'>Pricing</div>
         </a>
         <a href='#aboutus'>
         <div className='landing-menu-item'>About</div>
          </a>
      </div>
      <div className='landing-mobile-menu-burger-container' onClick={() => openMenu(!menu)}>
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
      </div>
      {menu ? null :
        <div className='landing-mobile-menu'>
          <Link to='/web-developer-portfolio/builder/horizon'>
          <div className='landing-mobile-menu-option'>
            Portfolio Builder
          </div>
          </Link>
          <a href='#templates'>
          <div className='landing-mobile-menu-option'>
            Templates
          </div>
            </a>
            <a href='#pricing'>
            <div className='landing-mobile-menu-option'>
            Pricing
          </div>
              </a>
              <a href='#aboutus'>
          <div className='landing-mobile-menu-option'>
            About
          </div>
          </a>
        </div>}
    </div>

  )
}