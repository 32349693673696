import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Builder from './views/builder';
import Preview from './views/preview'
import PurchaseHorizon from './views/purchaseHorizon';
import Home from './views/home';
import Templates from './views/templates';
import AboutUsView from './views/aboutUsView';
import PricingPage from './views/pricingPage'

import johnConfig from './views/johnConfig.json';
import jessicaConfig from './views/jessicaConfig.json';
import blankConfig from './views/blankConfig.json';
import ReactGA from "react-ga4";

ReactGA.initialize("G-D4N3K2J20D");

function App() {
  return (
    <Router>
      <div>
    <Routes>
    <Route path="/" exact element={<Home />} />
          <Route path="/web-developer-portfolio/builder/horizon" element={<Builder defaultConfig={blankConfig} />} />
          <Route path="/web-developer-portfolio/horizon/jessica" element={<Preview defaultConfig={jessicaConfig} />} />
          <Route path="/web-developer-portfolio/horizon/john" element={<Preview defaultConfig={johnConfig} />} />
          <Route exact path='/web-developer-portfolio/templates' element={<Templates />} />
          <Route exact path='/web-developer-portfolio/pricing' element={<PricingPage />} />
          <Route exact path='/aboutus' element={<AboutUsView />} />
    </Routes>
      </div>
    </Router>
  );
}

export default App;
