import React from 'react';

import LandingHeader from '../../components/LandingHeader';
import Pricing from '../pricing';
import './style.css';

export default function PricingPage({}) {
 return (
  <div>
      <LandingHeader />
      <div className='templates-box' />
      <Pricing />
  </div>
 )
}