import React from 'react'
import {Row, Col} from 'react-bootstrap';
import './style.css';

export default function AboutMe({config, isMobile, isTablet}) {

  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 0;
    mainWidth = 12
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }

  return (



    
    <Row className='about-me--container nopadding'>
      <Col xs={sideWidths}>
      </Col>
      <Col xs={mainWidth}>
        <div className='about-me--text-box'>
        <h1>{config.text.about_me_title}</h1>
        <div className="thick-white-bar">
        </div>
        <div className='about-me--desc-container'>
        {config.text.about_me}
        </div>
        </div>
      </Col>
      <Col xs={sideWidths}>
      </Col>
      <div style={{marginBottom: '150px'}} />
    </Row>
  )
}