import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';

export default function Education({config, isMobile, isTablet}) {

  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 0;
    mainWidth = 12
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }

  return (
    <Row className={`education--container nopadding`} id='education' style={{backgroundImage: `url(${config.backgrounds.education_background_image_url})`}}>

<Row className='nopadding full-width-banner'>
        <Col xs={sideWidths} />
        <Col xs={mainWidth} className='half-width-black-banner nopadding'>
        <div className="education--scroll-to-point"></div>
        <h1 id='education'>
            {config.text.header_education_title}
          </h1>
          <div id="education-white-bar" className="thick-white-bar-off">
          </div>
        </Col>
        <Col xs={sideWidths} />
      </Row>



      <Col xs={sideWidths}></Col>
      <Col xs={mainWidth} className="education--box">

        <div className={`education--school-logo`} style={{backgroundImage: `url(${config.backgrounds.education_institution_avatar_image_url})`}}>
        </div>
        <div className='nopadding full-width-black-banner'>
          <h2>
          {config.education.education_institution_name}
        </h2>
        <div className="thin-white-bar"></div>
        <h3>
          {config.education.education_institution_location}
        </h3>
        <h4 className="education--school-desc">
          {config.education.education_institution_desc}
        </h4>
        </div>
        {config.education.education_button_title && <div className="education--button" onClick={() => window.open(config.education.education_button_url, '_blank', 'noopener,noreferrer')}
>
          {config.education.education_button_title}
        </div>}
      </Col>
      <Col xs={sideWidths}></Col>
      <div style={{marginBottom: '150px'}} />
  </Row>
  )
}