import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function EducationPreview({config, isMobile, isTablet}) {

  return (
    <Row className={`education--container nopadding ${config.backgrounds.education_background_image_url}`} id='education'>

<Row className='nopadding full-width-banner'>
<Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6} className='half-width-black-banner nopadding'>

        <div className="education--scroll-to-point"></div>
        <h1 id='education'>
            {config.text.header_education_title}
          </h1>
          <div id="education-white-bar" className="thick-white-bar-off">
          </div>
        </Col>
        <Col sm={0} md={1} lg={2} xl={3}  />
      </Row>



      <Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6} className="education--box">


     

        <div className={`education--school-logo ${config.backgrounds.education_institution_avatar_image_url}`}>
        </div>
        <div className='nopadding full-width-black-banner'>
          <h2>
          {config.education.education_institution_name}
        </h2>
        <div className="thin-white-bar"></div>
        <h3>
          {config.education.education_institution_location}
        </h3>
        <h4 className="education--school-desc">
          {config.education.education_institution_desc}
        </h4>
        </div>
        {config.education.education_button_title && <div className="education--button" onClick={() => window.open(config.education.education_button_url, '_blank', 'noopener,noreferrer')}>
          {config.education.education_button_title}
        </div>}
      </Col>
      <Col sm={0} md={1} lg={2} xl={3}></Col>
      <div style={{marginBottom: '150px'}} />
  </Row>
  )
}