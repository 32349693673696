import _ from 'lodash';
import React, { useState } from 'react';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import { SketchPicker } from 'react-color'
import { TextField } from '@mui/material';
import AddTechModal from '../AddTechModal';
import Select from 'react-select';
import './style.css';

export default function ExperienceInput({ config, changeConfig, updateConfig, skillOptions, existingExperience, changeText, changeBackgrounds }) {

  const [openExperienceBackgroundColor, setOpenExperienceBackgroundColor] = useState(false);

  const emptyExperience = {
    workplace_avatar_image_url: '',
    workplace_position_title: '',
    workplace_name: '',
    workplace_start_date: '',
    workplace_end_date: '',
    workplace_duties: [''],
    technologies_used: []
  }

  const updateExperience = (value, label, index) => {
    const newData = _.cloneDeep(config);
    newData.experience[index][label] = value;
    changeConfig(newData)
  }

  const addExperience = () => {
    const newData = _.cloneDeep(config);
    newData.experience.push(emptyExperience);
    updateConfig(newData)
  }

  const addDuty = (index) => {
    const newData = _.cloneDeep(config);
    newData.experience[index]['workplace_duties'].push('');
    updateConfig(newData)
  }

  const removeDuty = (value, index) => {
    const newData = _.cloneDeep(config);
    const selectedSkills = _.cloneDeep(config.experience[index]['workplace_duties']);
    _.remove(selectedSkills, f => f === value);
    newData.experience[index]['workplace_duties'] = selectedSkills;
    updateConfig(newData)
  }

  const updateDuty = (value, index, dutyIndex) => {
    const newData = _.cloneDeep(config);
    newData.experience[index]['workplace_duties'][dutyIndex] = value
    updateConfig(newData)
  }

  const moveItemUp = (index, dutyIndex) => {

    const newData = _.cloneDeep(config)
    const existingDuties = _.cloneDeep(config.experience[index]['workplace_duties'])

    if (dutyIndex > 0) {
      const itemToMove = existingDuties[dutyIndex];
      existingDuties.splice(dutyIndex, 1);
      existingDuties.splice(dutyIndex - 1, 0, itemToMove);
      newData.experience[index]['workplace_duties'] = existingDuties
      updateConfig(newData)
    }
  }

  const moveItemDown = (index, dutyIndex) => {

    const newData = _.cloneDeep(config)
    const existingDuties = _.cloneDeep(config.experience[index]['workplace_duties'])

    if (dutyIndex < existingDuties.length - 1) {
      const itemToMove = existingDuties[dutyIndex];
      existingDuties.splice(dutyIndex, 1);
      existingDuties.splice(dutyIndex + 1, 0, itemToMove);
      newData.experience[index]['workplace_duties'] = existingDuties
      updateConfig(newData)
    }
  }

  const addTech = (value, index) => {
    const newConfig = _.cloneDeep(config);
    newConfig.experience[index]['technologies_used'] = value;
    updateConfig(newConfig)

  }
  const [showAddTech, setShowAddTech] = useState(false);
  const [techToAddTo, setTechToAddTo] = useState(null);

  const handleCloseAddTech = () => setShowAddTech(false);

  const handleAddTech = (techIndex) => {
    setTechToAddTo(techIndex)
    setShowAddTech(true);
  }

  const moveTechDown = (index, existingTech, originalIndex) => {
    const newConfig = _.cloneDeep(config);
    if (index < existingTech.length - 1) {
      const itemToMove = existingTech[index];
      existingTech.splice(index, 1);
      existingTech.splice(index + 1, 0, itemToMove);
      newConfig.experience[originalIndex]['technologies_used'] = existingTech
      updateConfig(newConfig)
    }
  }

  const moveTechUp = (index, existingTech, originalIndex) => {
    const newConfig = _.cloneDeep(config);
    if (index > 0) {
      const itemToMove = existingTech[index];
      existingTech.splice(index, 1);
      existingTech.splice(index - 1, 0, itemToMove);
      newConfig.experience[originalIndex]['technologies_used'] = existingTech
      updateConfig(newConfig)
    }
  }

  const removeTech = (e, existingTech, originalIndex) => {
    const newConfig = _.cloneDeep(config);
    const selectedTech = _.cloneDeep(existingTech);
    _.remove(selectedTech, f => f.value === e.value);
    newConfig.experience[originalIndex]['technologies_used'] = selectedTech
    updateConfig(newConfig)
  }

  const bigUpdate = (exp) => {
    changeConfig({experience: exp})
  }

  const moveExperienceUp = (index) => {
      if (index > 0) {
        const itemToMove = existingExperience[index];
        existingExperience.splice(index, 1);
        existingExperience.splice(index - 1, 0, itemToMove);
        bigUpdate(existingExperience)
      }
  }

  const moveExperienceDown = (index) => {
          if (index < existingExperience.length - 1) {
        const itemToMove = existingExperience[index];
        existingExperience.splice(index, 1);
        existingExperience.splice(index + 1, 0, itemToMove);
        bigUpdate(existingExperience)
      }
  }

  const deleteExperience = (experience) => {
      _.remove(existingExperience, f => f.workplace_name === experience.workplace_name);
      updateConfig({
        ...config,
        experience: existingExperience
      })
  }

  return (
    <Card style={{ paddingBottom: '150px' }} className='input-flash'>
          <Card.Header>
      Edit Experience
    </Card.Header>
    <Card.Body>
      <div className='config-input-container mb-3'>
        <TextField
          label='Experience Subtitle'
          style={{ width: '100%' }}
          value={config.text.experience_subtitle}
          onChange={e => changeText({ experience_subtitle: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
              <TextField
                label='Experience Background Color'
                style={{width: '100%'}}
                value={config.backgrounds.experience_background_color}
                onChange={e => changeBackgrounds({ experience_background_color: e.target.value })}
                />
                
                <div className='color-collapse-container'>
              <div className='color-collapse' style={{ backgroundColor: config.backgrounds.experience_background_color }} onClick={() => {
                setOpenExperienceBackgroundColor(!openExperienceBackgroundColor)
              }}>
              </div>
            </div>
              </div>
              <div className={openExperienceBackgroundColor ? 'contact--color-container' : 'color-container-hide'}>
              <SketchPicker color={config.backgrounds.experience_background_color} onChange={(e) => {
                changeBackgrounds({ experience_background_color: e.hex })
              }} />
            </div>
      {config.experience.map((e, i) => {
        return (
          <Card className='mt-3'>
            <Card.Header className='experience--header'>
              <div>
                Workplace #{i + 1}
              </div>
              <div className='experience-move-buttons-container'>
                <div>
                  <div onClick={() => moveExperienceDown(i)} style={{ marginRight: '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                    </svg>
                  </div>
                </div>
                <div>
                  <div onClick={() => moveExperienceUp(i)} style={{ marginRight: '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div>
                  <div className='delete-experience' onClick={() => deleteExperience(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </div>
                </div>
              </div>


            </Card.Header>
            <Card.Body>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Workplace Name'
                  style={{ width: '100%' }}
                  value={e.workplace_name}
                  onChange={e => updateExperience(e.target.value, 'workplace_name', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Workplace Logo URL'
                  style={{ width: '100%' }}
                  value={e.workplace_avatar_image_url}
                  onChange={e => updateExperience(e.target.value, 'workplace_avatar_image_url', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Position Title'
                  style={{ width: '100%' }}
                  value={e.workplace_position_title}
                  onChange={e => updateExperience(e.target.value, 'workplace_position_title', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Position Start Date'
                  style={{ width: '100%' }}
                  value={e.workplace_start_date}
                  onChange={e => updateExperience(e.target.value, 'workplace_start_date', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Position End Date'
                  style={{ width: '100%' }}
                  value={e.workplace_end_date}
                  onChange={e => updateExperience(e.target.value, 'workplace_end_date', i)}
                />
              </div>
              <div className='config-input-container mb-3 mt-5'>
                <Form.Label>
                  Workplace Duties
                </Form.Label>
                {
                  e.workplace_duties.length !== 0 ? e.workplace_duties.map((w, f) => {
                    return (
                      <div style={{ width: '100%' }} className='mt-3'>
                        <div  className='experience-input-container' style={{padding: '0px'}}>

                          <div style={{ width: '85%' }}>
                            <Form.Control
                              as='textarea'
                              value={w}
                              onChange={e => updateDuty(e.target.value, i, f)}
                            />
                          </div>

                          <div className='experience-control-container nopadding'>
                          <div className='delete-experience' onClick={() => removeDuty(w, i, f)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          </div>
                        </div>
                        </div>
                      </div>
                    )
                  }) :
                  
                  
                  <div className='nothing'>
                  <div>
                  No duties added.
                  </div>
                  <div>
                  Click the button below to add a duty.
                  </div>
                </div>
                }


                <Col xs={12} className='add-your-own-skill-container' style={{ marginTop: '15px' }}>
                  <Button variant="primary" onClick={() => addDuty(i)}>
                    Add Workplace Duty
                  </Button>
                </Col>

              </div>
              <div className='config-input-container mt-5'>
                <Form.Label>
                  Technologies Used
                </Form.Label>
              </div>
              <Row>
                <Col xs={12}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Technology"
                    value={config.experience[i].technologies_used}
                    controlShouldRenderValue={false}
                    isMulti={true}
                    onChange={tech => addTech(tech, i)}
                    options={_.cloneDeep(skillOptions)}
                  />
                </Col>
              </Row>
              <Row style={{ width: '100%', marginLeft: '0px' }}>
                <Col xs={12}>
                  {e.technologies_used.length !== 0 ? e.technologies_used.map((t, techIndex) => {
                    return (
                      <div className='rearrange-row'>
                        <Row className='rearrange-block' style={{ backgroundColor: t.color, color: t.labelColor }}>
                          <div onClick={() => moveTechDown(techIndex, config.experience[i].technologies_used, i)} className='rearrange-left'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                            </svg>
                          </div>
                          {t.label}
                          <div onClick={() => moveTechUp(techIndex, config.experience[i].technologies_used, i)} className='rearrange-right'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                            </svg>
                          </div>
                        </Row>
                        <div className='delete-skill' onClick={() => removeTech(t, config.experience[i].technologies_used, i)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </div>
                      </div>
                    )
                  }) :
                  <div className='nothing mt-3'>
                  <div>
                  No technologies selected.
                  </div>
                  <div>
                  Select from the dropdown menu above.
                  </div>
                </div>}
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col xs={12} className='add-your-own-skill-container'>
                  <Button variant="primary" onClick={() => handleAddTech(i)}>
                    Add Your Own Technology
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      })}
      <Row className='mt-3'>
        <Col xs='12' className='experience-flex-end'>
          <Button variant='success' onClick={() => addExperience()}>Add New Work Experience</Button>
        </Col>
      </Row>
      <AddTechModal
        config={config}
        showAddTech={showAddTech}
        updateConfig={updateConfig}
        handleCloseAddTech={handleCloseAddTech}
        existingTech={_.get(config, `experience[${techToAddTo}].technologies_used`, [])}
        experienceIndex={techToAddTo}
      />
      </Card.Body>
    </Card>
  )
}