import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const PurchaseModal = ({purchaseModalClose, purchaseModalOpen}) => {

  return (
    <div>
      <Modal show={purchaseModalOpen} onHide={purchaseModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Horizon Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{marginBottom: '15px'}}>
                    You're almost ready to showcase your tech career with a Devhorizons portfolio website! Follow the instructions below to get started -
                  </div>
                  <ul>
                    <li>If you haven't already, design your own Horizon portfolio in the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Builder</Link> and download the <span style={{fontWeight: 'bold'}}>config.json + images folder.</span></li>
                    <li>After you've purchased the Horizon template, simply replace the <span style={{fontWeight: 'bold'}}>config.json</span> file found at the root level with the <span style={{fontWeight: 'bold'}}>config.json</span> file you downloaded from the Builder.</li>
                    <li>Finally, replace the images found in the template's <span style={{fontWeight: 'bold'}}>"assets/images"</span> folder with the images you downloaded from the Builder.</li>
                    <li>That's it! Your portfolio website is ready to host.</li>
                    <li>You can always return to the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Builder</Link> and make changes to your portfolio by uploading your <span style={{fontWeight: 'bold'}}>config.json</span> file.</li>
                  </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={purchaseModalClose}>
            Close
          </Button>
          <a href='https://we.tl/p-SWqMrBRud4' target="_blank" rel="noopener noreferrer">
          <Button variant="success">
            Purchase Template ($12.99)
          </Button>
           </a>
        </Modal.Footer>
        <div>
        <div style={{marginBottom: '15px', textAlign: 'center', color: 'grey'}}>You will be redirected to Wetransfer to checkout and download.</div>
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseModal;
