import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css';

export default function Squares() {

  return (
    <div>
      <Row className='nopadding squares-mobile' style={{background: 'linear-gradient(rgb(237 237 237), #b6b6b6)'}}>

        <div className='squares-why-skillsho'>
          Why Devhorizons?
        </div>

        <div className='squares-mobile-box-container'>
        <div className='squares-mobile-image-container'>
        <div className='coding-icon-mobile' />
        </div>

    <div className='squares-mobile-header'>
    <div className='square-desc-title-mobile'>
                Cleanly Coded
              </div>
              <div className='square-desc'>
              Devhorizons templates are meticulously crafted with clarity and consistency in mind, allowing you to effortlessly tailor the template to your unique style and preferences. Using the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>, developers can quickly navigate and modify sections to fit their needs.
              </div>
    </div>
        </div>


        <div className='squares-mobile-box-container'>
        <div className='squares-mobile-image-container'>
        <div className='html-icon-mobile' />
        </div>

    <div className='squares-mobile-header'>
    <div className='square-desc-title-mobile'>
    Just HTML, CSS and Javascript
              </div>
              <div className='square-desc'>
              No bulky framework necessary! Our lightweight portfolios consist of a single index.html file, organized CSS, simplified Javascript and a reusable config.json file, allowing for quick set-up and super-fast load time.
              </div>
    </div>
        </div>


        <div className='squares-mobile-box-container'>
        <div className='squares-mobile-image-container'>
        <div className='devices-icon-mobile' />
        </div>

    <div className='squares-mobile-header'>
    <div className='square-desc-title-mobile'>
    Mobile Responsive
              </div>
              <div className='square-desc'>
              All of our code is written with mobile-responsiveness in mind, enabling your portfolio to maintain its visual excellence across all devices. No matter the screen size, you are guaranteed a seamless user experience.
              </div>
    </div>
        </div>


        <div className='squares-mobile-box-container'>
        <div className='squares-mobile-image-container'>
        <div className='document-icon-mobile' />
        </div>

    <div className='squares-mobile-header'>
    <div className='square-desc-title-mobile'>
                Super Fast!
              </div>
              <div className='square-desc'>
              Our <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link> allows you to create an attractive personal website in just minutes without having to spend hours coding yourself!
              </div>
    </div>
        </div>



      </Row>

<Row className='nopadding squares-laptop' style={{height: '100%', background: 'linear-gradient(rgb(237 237 237), #b6b6b6)'}}>
<div className='squares-why-skillsho'>
          Why Devhorizons?
        </div>
        <Col xs={0} md={1} lg={2} xl={3}  className='nopadding' />
        <Col xs={12} md={10} lg={8} xl={6} className='nopadding'>

          <div className='square-left-container'>
            <div className='triangle-left' />
            <div className='square-icon-container'>
    <div className='coding-icon' />
            </div>
            <div className='triangle-right' />
            <div className='square-desc-container'>
              <div className='square-desc-title'>
                Cleanly Coded
              </div>
              <div className='square-desc'>
              Devhorizons templates are meticulously crafted with clarity and consistency in mind, allowing you to effortlessly tailor the template to your unique style and preferences. Using the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>, developers can quickly navigate and modify sections to fit their needs.
              </div>
            </div>
            <div className='triangle-right-2' />
          </div>

          <div className='square-left-container'>
          <div className='triangle-left-reverse' />
            <div className='square-desc-container'>
              <div className='square-desc-title'>
                Just HTML, CSS and Javascript
              </div>
              <div className='square-desc'>
No bulky framework necessary! Our lightweight portfolios consist of a single index.html file, organized CSS, simplified Javascript and a reusable config.json file, allowing for quick set-up and super-fast load time.
              </div>
            </div>
            <div className='triangle-right-2-reverse' />
            <div className='square-icon-container'>
    <div className='html-icon' />
            </div>
            <div className='triangle-right-reverse' />
          </div>

          <div className='square-left-container'>
            <div className='triangle-left' />
            <div className='square-icon-container'>
    <div className='devices-icon' />
            </div>
            <div className='triangle-right' />
            <div className='square-desc-container'>
              <div className='square-desc-title'>
                Mobile Responsive
              </div>
              <div className='square-desc'>
              All of our code is written with mobile-responsiveness in mind, enabling your portfolio to maintain its visual excellence across all devices. No matter the screen size, you are guaranteed a seamless user experience.
              </div>
            </div>
            <div className='triangle-right-2' />
          </div>

          <div className='square-left-container'>
          <div className='triangle-left-reverse' />
            <div className='square-desc-container'>
              <div className='square-desc-title'>
                Super Fast!
              </div>
              <div className='square-desc'>
              Our <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link> allows you to create an attractive personal website in just minutes without having to spend hours coding yourself!
              </div>
            </div>
            <div className='triangle-right-2-reverse' />
            <div className='square-icon-container'>
    <div className='document-icon' />
            </div>
            <div className='triangle-right-reverse' />
          </div>
        </Col>
        <div className='squares-why-skillsho'>
    
        </div>
        <Col xs={0} md={1} lg={2} xl={3}  className='nopadding'>

          </Col>
      </Row>
    </div>
  )
}