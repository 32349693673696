import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color'
import { TextField, InputLabel } from '@mui/material';
import './style.css';

function AddSkillModal({ showAddSkill, handleCloseAddSkill, existingSkills, bigUpdate }) {

  const emptySkillState = {
    label: 'New Skill',
    value: 'New Skill',
    title: 'New Skill',
    color: 'rgb(13,110,251)',
    labelColor: '#ffffff'
  }

  const [currentSkill, updateCurrentSkill] = useState(emptySkillState)

  const [openAddSkillColor, setOpenAddSkillColor] = useState(false);

  const { title, color } = currentSkill;

  const handleClose = () => {
    updateCurrentSkill(emptySkillState);
    handleCloseAddSkill();
    setOpenAddSkillColor(false)
  }

  const handleAddSkill = () => {
    const newSkills = _.cloneDeep(existingSkills);
    newSkills.push(currentSkill)
    bigUpdate(newSkills)
    updateCurrentSkill(emptySkillState);
    handleCloseAddSkill();
    setOpenAddSkillColor(false)
  }

  return (
    <>
      <Modal show={showAddSkill} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            If your desired skill / language is not available in the "Skills" dropdown menu, you can add your own here!
          </div>
          <Row className='demo-skill-container mt-5 mb-5'>
            <div class="skills--skills-box">
              <div class="skills--skill-tab" style={{ backgroundColor: color }}></div>
              <div class="skills--skill-tab-2" style={{ backgroundColor: color }}></div>
              <div class="skills--skill-block">
                {title}
              </div>
            </div>
          </Row>
          <Row className='mt-3'>
            <Col xs={3}>

            </Col>
            <Col xs={6}>
              <Row>
                <TextField
                  label='Skill Name'
                  style={{ width: '100%' }}
                  value={currentSkill.label}
                  onChange={e => updateCurrentSkill({
                    ...currentSkill,
                    label: e.target.value,
                    value: e.target.value,
                    title: e.target.value
                  })}
                />
              </Row>
              <Row className='mt-3' style={{height: '56px'}}>
                <TextField
                  id='add-skill-field'
                  label="Skill Background Color"
                  style={{ width: '100%' }}
                  value={currentSkill.color}
                  onChange={e => {
                    updateCurrentSkill({
                      ...currentSkill,
                      color: e.target.value,
                    })
                  }}
                />
                <div className='add-skill-color-collapse-container'>
                  <div className='color-collapse' style={{ backgroundColor: currentSkill.color }} onClick={() => {
                    setOpenAddSkillColor(!openAddSkillColor)
                  }}>
                  </div>
                </div>
                <div className={openAddSkillColor ? 'add-skill-color-container' : 'add-skill-color-container-hide'}>
                  <SketchPicker color={currentSkill.color} onChange={(e) => {
                    updateCurrentSkill({
                      ...currentSkill,
                      color: e.hex,
                    })
                  }} />
                </div>
              </Row>
            </Col>
            <Col xs={3}>

            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddSkill}>
            Add Skill
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddSkillModal;