const skillOptions = [
  {
    "label": "Angular",
    "value": "Angular",
    "title": "Angular",
    "color": "#DD0031",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Apache",
    "value": "Apache",
    "title": "Apache",
    "color": "#D22128",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Adobe Photoshop",
    "value": "Adobe Photoshop",
    "title": "Adobe Photoshop",
    "color": "#001E36",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Adobe Illustrator",
    "value": "Adobe Illustrator",
    "title": "Adobe Illustrator",
    "color": "#FF9A00",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Axure RP",
    "value": "Axure RP",
    "title": "Axure RP",
    "color": "#5E8D87",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "AWS",
    "value": "AWS",
    "title": "AWS",
    "color": "#FF9900",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Assembly Language",
    "value": "Assembly Language",
    "title": "Assembly Language",
    "color": "#6E4C13",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Android (Java/Kotlin)",
    "value": "Android (Java/Kotlin)",
    "title": "Android (Java/Kotlin)",
    "color": "#3DDC84",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Apache Hadoop",
    "value": "Apache Hadoop",
    "title": "Apache Hadoop",
    "color": "#DE4E2A",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Bootstrap",
    "value": "Bootstrap",
    "title": "Bootstrap",
    "color": "#563D7C",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Babel",
    "value": "Babel",
    "title": "Babel",
    "color": "#F5D742",
    "labelColor": "#000000"
  },
  {
    "label": "Brackets",
    "value": "Brackets",
    "title": "Brackets",
    "color": "#1C1E26",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Balsamiq",
    "value": "Balsamiq",
    "title": "Balsamiq",
    "color": "#E76110",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Bash",
    "value": "Bash",
    "title": "Bash",
    "color": "#4EAA25",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Bitdefender",
    "value": "Bitdefender",
    "title": "Bitdefender",
    "color": "#0F97D0",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Bamboo",
    "value": "Bamboo",
    "title": "Bamboo",
    "color": "#0052CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "CSS",
    "value": "CSS",
    "title": "CSS",
    "color": "#1572B6",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "C#",
    "value": "C#",
    "title": "C#",
    "color": "#239120",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "ColorHex",
    "value": "ColorHex",
    "title": "ColorHex",
    "color": "#009688",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Canva",
    "value": "Canva",
    "title": "Canva",
    "color": "#00C4CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "C",
    "value": "C",
    "title": "C",
    "color": "#A8B9CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "C++",
    "value": "C++",
    "title": "C++",
    "color": "#00599C",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "C#",
    "value": "C#",
    "title": "C#",
    "color": "#239120",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Cisco",
    "value": "Cisco",
    "title": "Cisco",
    "color": "#1BA0D7",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Dart",
    "value": "Dart",
    "title": "Dart",
    "color": "#0175C2",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Django",
    "value": "Django",
    "title": "Django",
    "color": "#092E20",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Docker",
    "value": "Docker",
    "title": "Docker",
    "color": "#2496ED",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ember.js",
    "value": "Ember.js",
    "title": "Ember.js",
    "color": "#E04E39",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Elementor",
    "value": "Elementor",
    "title": "Elementor",
    "color": "#00BFFF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Elixir",
    "value": "Elixir",
    "title": "Elixir",
    "color": "#4B275F",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Eclipse",
    "value": "Eclipse",
    "title": "Eclipse",
    "color": "#2C2255",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Elasticsearch",
    "value": "Elasticsearch",
    "title": "Elasticsearch",
    "color": "#005571",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Firebase",
    "value": "Firebase",
    "title": "Firebase",
    "color": "#FFCA28",
    "labelColor": "#000000"
  },
  {
    "label": "Flask",
    "value": "Flask",
    "title": "Flask",
    "color": "#000000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Figma",
    "value": "Figma",
    "title": "Figma",
    "color": "#F24E1E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Flutter",
    "value": "Flutter",
    "title": "Flutter",
    "color": "#02569B",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Fortinet",
    "value": "Fortinet",
    "title": "Fortinet",
    "color": "#5177A8",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Falcon",
    "value": "Falcon",
    "title": "Falcon",
    "color": "#E03E2E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Git",
    "value": "Git",
    "title": "Git",
    "color": "#F05032",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "GitHub",
    "value": "GitHub",
    "title": "GitHub",
    "color": "#181717",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Go (Golang)",
    "value": "Go (Golang)",
    "title": "Go (Golang)",
    "color": "#00ADD8",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Google Cloud",
    "value": "Google Cloud",
    "title": "Google Cloud",
    "color": "#4285F4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "GitLab",
    "value": "GitLab",
    "title": "GitLab",
    "color": "#FC6D26",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "GreenHopper",
    "value": "GreenHopper",
    "title": "GreenHopper",
    "color": "#0052CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "HTML",
    "value": "HTML",
    "title": "HTML",
    "color": "#E34F26",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Haskell",
    "value": "Haskell",
    "title": "Haskell",
    "color": "#5D4F85",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "HashiCorp",
    "value": "HashiCorp",
    "title": "HashiCorp",
    "color": "#007AC1",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ionic",
    "value": "Ionic",
    "title": "Ionic",
    "color": "#3880FF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "InVision",
    "value": "InVision",
    "title": "InVision",
    "color": "#FF3366",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "ITIL",
    "value": "ITIL",
    "title": "ITIL",
    "color": "#008CC3",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "JavaScript",
    "value": "JavaScript",
    "title": "JavaScript",
    "color": "#F7DF1E",
    "labelColor": "#000000"
  },
  {
    "label": "Joomla",
    "value": "Joomla",
    "title": "Joomla",
    "color": "#FC9F5E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Java",
    "value": "Java",
    "title": "Java",
    "color": "#007396",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Julia",
    "value": "Julia",
    "title": "Julia",
    "color": "#5391CD",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Joomla",
    "value": "Joomla",
    "title": "Joomla",
    "color": "#FC9F5E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Jenkins",
    "value": "Jenkins",
    "title": "Jenkins",
    "color": "#D24939",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Jira",
    "value": "Jira",
    "title": "Jira",
    "color": "#0052CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kotlin",
    "value": "Kotlin",
    "title": "Kotlin",
    "color": "#0095D5",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kuler",
    "value": "Kuler",
    "title": "Kuler",
    "color": "#D30061",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kotlin",
    "value": "Kotlin",
    "title": "Kotlin",
    "color": "#0095D5",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kaspersky",
    "value": "Kaspersky",
    "title": "Kaspersky",
    "color": "#0066CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kubernetes",
    "value": "Kubernetes",
    "title": "Kubernetes",
    "color": "#326CE5",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Kanban",
    "value": "Kanban",
    "title": "Kanban",
    "color": "#2F6FA6",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Laravel",
    "value": "Laravel",
    "title": "Laravel",
    "color": "#FF2D20",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Loom",
    "value": "Loom",
    "title": "Loom",
    "color": "#2F80ED",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Lua",
    "value": "Lua",
    "title": "Lua",
    "color": "#000080",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Linux",
    "value": "Linux",
    "title": "Linux",
    "color": "#FCC624",
    "labelColor": "#000000"
  },
  {
    "label": "Logstash",
    "value": "Logstash",
    "title": "Logstash",
    "color": "#1E90FF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Leankit",
    "value": "Leankit",
    "title": "Leankit",
    "color": "#00A1E0",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Materialize CSS",
    "value": "Materialize CSS",
    "title": "Materialize CSS",
    "color": "#FF4081",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "MongoDB",
    "value": "MongoDB",
    "title": "MongoDB",
    "color": "#47A248",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Material Design",
    "value": "Material Design",
    "title": "Material Design",
    "color": "#2196F3",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Matlab",
    "value": "Matlab",
    "title": "Matlab",
    "color": "#0076A8",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "MySQL",
    "value": "MySQL",
    "title": "MySQL",
    "color": "#4479A1",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Maven",
    "value": "Maven",
    "title": "Maven",
    "color": "#C71A36",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Miro",
    "value": "Miro",
    "title": "Miro",
    "color": "#0052CC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Node.js",
    "value": "Node.js",
    "title": "Node.js",
    "color": "#8CC84B",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Next.js",
    "value": "Next.js",
    "title": "Next.js",
    "color": "#FFFFFF",
    "labelColor": "#000000"
  },
  {
    "label": "Nuxt.js",
    "value": "Nuxt.js",
    "title": "Nuxt.js",
    "color": "#00C58E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": ".NET",
    "value": ".NET",
    "title": ".NET",
    "color": "#512BD4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Nagios",
    "value": "Nagios",
    "title": "Nagios",
    "color": "#2D9830",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "NTask",
    "value": "NTask",
    "title": "NTask",
    "color": "#7F8FA4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Oracle",
    "value": "Oracle",
    "title": "Oracle",
    "color": "#F80000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Opera",
    "value": "Opera",
    "title": "Opera",
    "color": "#FF1B2D",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Objective-C",
    "value": "Objective-C",
    "title": "Objective-C",
    "color": "#438EFF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Oracle",
    "value": "Oracle",
    "title": "Oracle",
    "color": "#F80000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Office 365",
    "value": "Office 365",
    "title": "Office 365",
    "color": "#0078D4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Octopus Deploy",
    "value": "Octopus Deploy",
    "title": "Octopus Deploy",
    "color": "#2E3C4E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Outplanr",
    "value": "Outplanr",
    "title": "Outplanr",
    "color": "#1273F8",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Pug",
    "value": "Pug",
    "title": "Pug",
    "color": "#A86454",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "PHP",
    "value": "PHP",
    "title": "PHP",
    "color": "#777BB4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Perl",
    "value": "Perl",
    "title": "Perl",
    "color": "#39457E",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "PostgreSQL",
    "value": "PostgreSQL",
    "title": "PostgreSQL",
    "color": "#336791",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Python",
    "value": "Python",
    "title": "Python",
    "color": "#3776AB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Puppet",
    "value": "Puppet",
    "title": "Puppet",
    "color": "#F16822",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Pivotal Tracker",
    "value": "Pivotal Tracker",
    "title": "Pivotal Tracker",
    "color": "#0C61E4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Qt",
    "value": "Qt",
    "title": "Qt",
    "color": "#41CD52",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "QuarkXPress",
    "value": "QuarkXPress",
    "title": "QuarkXPress",
    "color": "#B30638",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Qualcomm",
    "value": "Qualcomm",
    "title": "Qualcomm",
    "color": "#0072C6",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Quay",
    "value": "Quay",
    "title": "Quay",
    "color": "#13DAEC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "QuickScrum",
    "value": "QuickScrum",
    "title": "QuickScrum",
    "color": "#4E97C1",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "React",
    "value": "React",
    "title": "React",
    "color": "#61DAFB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Redux",
    "value": "Redux",
    "title": "Redux",
    "color": "#754ABB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ruby",
    "value": "Ruby",
    "title": "Ruby",
    "color": "#CC342D",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ruby On Rails",
    "value": "Ruby On Rails",
    "title": "Ruby On Rails",
    "color": "#CC342D",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Red Hat",
    "value": "Red Hat",
    "title": "Red Hat",
    "color": "#EE0000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Rust",
    "value": "Rust",
    "title": "Rust",
    "color": "#000000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ruby",
    "value": "Ruby",
    "title": "Ruby",
    "color": "#CC342D",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Raspberry Pi",
    "value": "Raspberry Pi",
    "title": "Raspberry Pi",
    "color": "#C51A4A",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Rancher",
    "value": "Rancher",
    "title": "Rancher",
    "color": "#0075A8",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Redmine",
    "value": "Redmine",
    "title": "Redmine",
    "color": "#8B2727",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Sass",
    "value": "Sass",
    "title": "Sass",
    "color": "#CC6699",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Spring",
    "value": "Spring",
    "title": "Spring",
    "color": "#6DB33F",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Sketch",
    "value": "Sketch",
    "title": "Sketch",
    "color": "#F7B500",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Swift",
    "value": "Swift",
    "title": "Swift",
    "color": "#FFAC45",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Symantec",
    "value": "Symantec",
    "title": "Symantec",
    "color": "#FE5000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Splunk",
    "value": "Splunk",
    "title": "Splunk",
    "color": "#000000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Scrumwise",
    "value": "Scrumwise",
    "title": "Scrumwise",
    "color": "#55B3D2",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "TypeScript",
    "value": "TypeScript",
    "title": "TypeScript",
    "color": "#3178C6",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Tailwind CSS",
    "value": "Tailwind CSS",
    "title": "Tailwind CSS",
    "color": "#38B2AC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "TensorFlow",
    "value": "TensorFlow",
    "title": "TensorFlow",
    "color": "#FF6F00",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Trend Micro",
    "value": "Trend Micro",
    "title": "Trend Micro",
    "color": "#D71921",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Terraform",
    "value": "Terraform",
    "title": "Terraform",
    "color": "#623CE4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Targetprocess",
    "value": "Targetprocess",
    "title": "Targetprocess",
    "color": "#2E5D9B",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Ubuntu",
    "value": "Ubuntu",
    "title": "Ubuntu",
    "color": "#E95420",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "UIkit",
    "value": "UIkit",
    "title": "UIkit",
    "color": "#2396F3",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Unity",
    "value": "Unity",
    "title": "Unity",
    "color": "#000000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "UpGuard",
    "value": "UpGuard",
    "title": "UpGuard",
    "color": "#52A3FF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Unfuddle",
    "value": "Unfuddle",
    "title": "Unfuddle",
    "color": "#14A6F7",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Vue.js",
    "value": "Vue.js",
    "title": "Vue.js",
    "color": "#4FC08D",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Vercel",
    "value": "Vercel",
    "title": "Vercel",
    "color": "#000000",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Vim",
    "value": "Vim",
    "title": "Vim",
    "color": "#019733",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Visual Studio Code",
    "value": "Visual Studio Code",
    "title": "Visual Studio Code",
    "color": "#007ACC",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "VMware",
    "value": "VMware",
    "title": "VMware",
    "color": "#607078",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Vagrant",
    "value": "Vagrant",
    "title": "Vagrant",
    "color": "#1563FF",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "VersionOne",
    "value": "VersionOne",
    "title": "VersionOne",
    "color": "#004165",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Webpack",
    "value": "Webpack",
    "title": "Webpack",
    "color": "#8DD6F9",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "WordPress",
    "value": "WordPress",
    "title": "WordPress",
    "color": "#21759B",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Wix",
    "value": "Wix",
    "title": "Wix",
    "color": "#333333",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "WebAssembly",
    "value": "WebAssembly",
    "title": "WebAssembly",
    "color": "#654FF0",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "WD-40",
    "value": "WD-40",
    "title": "WD-40",
    "color": "#0069AA",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Western Digital",
    "value": "Western Digital",
    "title": "Western Digital",
    "color": "#0054A0",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Wazuh",
    "value": "Wazuh",
    "title": "Wazuh",
    "color": "#E44D26",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Workfront",
    "value": "Workfront",
    "title": "Workfront",
    "color": "#D00020",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Xamarin",
    "value": "Xamarin",
    "title": "Xamarin",
    "color": "#3498DB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "XD (Adobe)",
    "value": "XD (Adobe)",
    "title": "XD (Adobe)",
    "color": "#FF26BE",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "XML",
    "value": "XML",
    "title": "XML",
    "color": "#FC6600",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Xcode",
    "value": "Xcode",
    "title": "Xcode",
    "color": "#147EFB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "XL Deploy",
    "value": "XL Deploy",
    "title": "XL Deploy",
    "color": "#44BFA3",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "XPlanner",
    "value": "XPlanner",
    "title": "XPlanner",
    "color": "#4285F4",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Yii",
    "value": "Yii",
    "title": "Yii",
    "color": "#4FA3D1",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Yellow",
    "value": "Yellow",
    "title": "Yellow",
    "color": "#FFD300",
    "labelColor": "#000000"
  },
  {
    "label": "YAML",
    "value": "YAML",
    "title": "YAML",
    "color": "#8CBF26",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Yarn",
    "value": "Yarn",
    "title": "Yarn",
    "color": "#2C8EBB",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Yodiz",
    "value": "Yodiz",
    "title": "Yodiz",
    "color": "#52C1AD",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Zend",
    "value": "Zend",
    "title": "Zend",
    "color": "#68B604",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Zeplin",
    "value": "Zeplin",
    "title": "Zeplin",
    "color": "#F35F5F",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Zapier",
    "value": "Zapier",
    "title": "Zapier",
    "color": "#FF4A00",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Zsh",
    "value": "Zsh",
    "title": "Zsh",
    "color": "#0A0505",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "Zebra Technologies",
    "value": "Zebra Technologies",
    "title": "Zebra Technologies",
    "color": "#0095D9",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "ZenHub",
    "value": "ZenHub",
    "title": "ZenHub",
    "color": "#F69939",
    "labelColor": "#FFFFFF"
  },
  {
    "label": "ZenTao",
    "value": "ZenTao",
    "title": "ZenTao",
    "color": "#0067E0",
    "labelColor": "#FFFFFF"
  }
];

export default skillOptions;