import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';

export default function Experience({config, isMobile, isTablet, preview}) {

  const renderExperience = () => {

    return config.experience.map((e, i) => {
      return (
        <div className="experience--company-container">
          <div className={`experience--logo company-1-logo`} style={{backgroundImage: `url(${e.workplace_avatar_image_url})`}}></div>
          <h3>
            {e.workplace_position_title} - {e.workplace_name}
          </h3>
          <div className="thin-white-bar"></div>
          <h4>
            {e.workplace_start_date} - {e.workplace_end_date}
          </h4>
          <div className="experience--bullet-list">
              {e.workplace_duties.map(e => {
                return (
                  <h4>- {e}</h4>
                )
              })}
          </div>
          <div className='experience--technologies-used'>Technologies used:</div>
          <div className="experience--thin-white-bar"></div>
          <div className="experience--languages-container" id="company-3-technologies">
            {e.technologies_used.map((f, index) => {
              return (
                <div class={preview ? "experience--language-off" : "experience--language-on"} id={`technology-${index}-in-${i}`} style={{backgroundColor: f.color, color: f.labelColor}}>
                {f.title}
                </div>
              )
            })}

          </div>
        </div>
      )
    })
  }

  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 0;
    mainWidth = 12
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }

  return (
    <Row className="experience--container nopadding" id='experience' style={{background: `${config.backgrounds.experience_background_color}`}}>
<Col xs={sideWidths}>
</Col>
<Col xs={mainWidth} className="experience--box-container">
  <div className="full-width-banner">
      <h1>
        {config.text.header_experience_title}
      </h1>
      <div id="experience-white-bar" className="thick-white-bar-off">
      </div>
      <h3 className="vertical-slide-animation" id="experience-subtitle-slide-up">
        {config.text.experience_subtitle}
      </h3>
  </div>

  {renderExperience()}

</Col>
<Col xs={sideWidths}>
</Col>
<div style={{marginBottom: '150px'}} />
</Row>
  )
}