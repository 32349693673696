import _ from 'lodash';
import React, {useState} from 'react';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import { SketchPicker } from 'react-color'
import { TextField } from '@mui/material';
import './style.css';

export default function ApplicationsInput({ config, changeText, changeConfig, changeBackgrounds, updateConfig, existingApps }) {

  const newApplication = {
  application_avatar: '',
  application_workplace_name: '',
  application_title: '',
  application_desc: '',
  application_button_1_title: '',
  application_button_1_url: '',
  application_button_2_title: '',
  application_button_2_url: '',
  application_background_image_url: ''
  }

  const [openApplicationsBackgroundColor, setOpenApplicationsBackgroundColor] = useState(false);

  const updateApplication = (value, label, index) => {
    const newData = _.cloneDeep(config);
    newData.applications[index][label] = value;
    changeConfig(newData)
  }

  const addApplication = () => {
    const newData = _.cloneDeep(config);
    newData.applications.push(newApplication);
    updateConfig(newData)
  }

const bigUpdate = (apps) => {
  changeConfig({applications: apps})
}

  const moveAppUp = (index) => {
    if (index > 0) {
      const itemToMove = existingApps[index];
      existingApps.splice(index, 1);
      existingApps.splice(index - 1, 0, itemToMove);
      bigUpdate(existingApps)
    }
  }

  const moveAppDown = (index) => {
    if (index < existingApps.length - 1) {
      const itemToMove = existingApps[index];
      existingApps.splice(index, 1);
      existingApps.splice(index + 1, 0, itemToMove);
      bigUpdate(existingApps)
    }
  }

  const deleteApp = (app) => {
    const existingApps = _.cloneDeep(config.applications);
    _.remove(existingApps, f => f.application_title === app.application_title);
    updateConfig({
      ...config,
      applications: existingApps
    })
  }

  return (
    <Card style={{ paddingBottom: '150px' }} className='input-flash'>
            <Card.Header>
        Edit Applications
      </Card.Header>
      <Card.Body>
      
      <div className='config-input-container mb-3'>
<TextField
              label="Applications Subtitle"
              style={{ width: '100%' }}
              value={config.text.applications_subtitle}
              onChange={e => changeText({ applications_subtitle: e.target.value })}
            />
      </div>
      <div className='config-input-container mb-3'>



<TextField
              label="Applications Background Color"
              style={{ width: '100%' }}
              value={config.backgrounds.applications_background_color}
              onChange={e => changeBackgrounds({ applications_background_color: e.target.value })}
            />


                    <div className='color-collapse-container'>
              <div className='color-collapse' style={{ backgroundColor: config.backgrounds.applications_background_color }} onClick={() => {
                setOpenApplicationsBackgroundColor(!openApplicationsBackgroundColor)
              }}>
              </div>
            </div>
      </div>
      <div className={openApplicationsBackgroundColor ? 'applications--color-container' : 'color-container-hide'}>
              <SketchPicker color={config.backgrounds.applications_background_color} onChange={(e) => {
                changeBackgrounds({ applications_background_color: e.hex })
              }} />
            </div>

      {existingApps.map((e, i) => {
        return (
          <Card className='mt-3'>
            <Card.Header className='experience--header'>
              <div>
                Application #{i + 1}
              </div>
              <div className='experience-move-buttons-container'>
                <div>
                  <div className='hide-rearrange-arrows-on-mobile' onClick={() => moveAppDown(i)} style={{ marginRight: '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                    </svg>
                  </div>
                </div>
                <div>
                  <div className='hide-rearrange-arrows-on-mobile' onClick={() => moveAppUp(i)} style={{ marginRight: '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                </div>
                <div>
                  <div className='delete-experience' onClick={() => deleteApp(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </div>
                </div>
              </div>


            </Card.Header>
            <Card.Body>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Title'
                  style={{ width: '100%' }}
                  value={e.application_title}
                  onChange={e => updateApplication(e.target.value, 'application_title', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Description'
                  style={{ width: '100%' }}
                  value={e.application_desc}
                  onChange={e => updateApplication(e.target.value, 'application_desc', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Workplace Name'
                  style={{ width: '100%' }}
                  value={e.application_workplace_name}
                  onChange={e => updateApplication(e.target.value, 'application_workplace_name', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Avatar Image URL'
                  style={{ width: '100%' }}
                  value={e.application_avatar}
                  onChange={e => updateApplication(e.target.value, 'application_avatar', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Background Image URL'
                  style={{ width: '100%' }}
                  value={e.application_background_image_url}
                  onChange={e => updateApplication(e.target.value, 'application_background_image_url', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Button 1 Title'
                  style={{ width: '100%' }}
                  value={e.application_button_1_title}
                  onChange={e => updateApplication(e.target.value, 'application_button_1_title', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Button 1 URL'
                  style={{ width: '100%' }}
                  value={e.application_button_1_url}
                  onChange={e => updateApplication(e.target.value, 'application_button_1_url', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Button 2 Title'
                  style={{ width: '100%' }}
                  value={e.application_button_2_title}
                  onChange={e => updateApplication(e.target.value, 'application_button_2_title', i)}
                />
              </div>
              <div className='config-input-container mb-3'>
                <TextField
                  label='Application Button 1 URL'
                  style={{ width: '100%' }}
                  value={e.application_button_2_url}
                  onChange={e => updateApplication(e.target.value, 'application_button_2_url', i)}
                />
              </div>
              <div className='config-input-container mb-3 show-rearrange-buttons-on-mobile'>
          <Button variant='outline-secondary' className='move-application-buttons' onClick={() => moveAppUp(i)}>
            <div>
            Move Application Up
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                    </svg> 
          </Button>
              </div>
              <div className='config-input-container mb-3 show-rearrange-buttons-on-mobile'>
              <Button variant='outline-secondary' className='move-application-buttons' onClick={() => moveAppDown(i)}>
              <div>
            Move Application Down
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                    </svg>
          </Button>
              </div>
            </Card.Body>
          </Card>
        )
      })}






      <Row className='mt-3'>
        <Col xs='12' className='experience-flex-end'>
          <Button variant='success' onClick={() => addApplication()}>Add New Application</Button>
        </Col>
      </Row>

      </Card.Body>
    </Card>
  )
}




