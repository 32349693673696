import React from 'react'
import { Col, Row } from 'react-bootstrap';

export default function ApplicationsPreview({config, preview}) {

    // col-sm-0 col-md-1 col-lg-2 col-xl-3

  return (
    <Row className="applications--container nopadding" style={{background: `${config.backgrounds.applications_background_color}`}}>
        <Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6} className="applications--application-container">
      <div className="full-width-banner">
          <h1 id='applications'>
            {config.text.header_applications_title}
          </h1>
          <div id="applications-white-bar" className="thick-white-bar-off">
          </div>
          <h3 className="vertical-slide-animation" id="applications-subtitle-slide-up">
            {config.text.applications_subtitle}
          </h3>
      </div>

{config.applications.map((e, i) => {
  return (
    <div className="applications--box col-12 nopadding">
    <div className={`applications--inner-box ${e.application_background_image_url}`}>
    <div className={`applications--logo company-1-logo ${e.application_avatar}`}>
      </div>
      <h4>
        {e.application_workplace_name}
      </h4>
      <div className='applications--desc-container'>
      <h2 className={`horizontal-slide-left-animation ${preview ? 'horizontal-slide-left-animation-off' : 'horizontal-slide-left-animation-on'}`} id={`application-${i}-title-slide-left`}>
        {e.application_title}
      </h2>
      <div className="thin-white-bar"></div>
      <h4 className={`applications--desc ${preview ? 'horizontal-slide-right-animation-off' : 'horizontal-slide-right-animation-on'}`} id={`application-${i}-desc-slide-right`}>
        {
          e.application_desc
        }
      </h4>
        </div>
      <div className="applications--button-container">
        {e.application_button_1_title &&
                <div className="applications--screenshots-button" onClick={() => window.open(e.application_button_1_url, '_blank', 'noopener,noreferrer')}>
                {e.application_button_1_title}
              </div>
        }
                {e.application_button_2_title &&
        <div className="applications--demo-button" onClick={() => window.open(e.application_button_2_url, '_blank', 'noopener,noreferrer')}>
        {e.application_button_2_title}
      </div>
        }


      </div>
    </div>
  </div>
  )
})}
    </Col>
    <Col sm={0} md={1} lg={2} xl={3}>
    </Col>
    <div style={{marginBottom: '150px'}} />
  </Row>
  )
}