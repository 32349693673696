import React from 'react';

import LandingHeader from '../../components/LandingHeader';
import BuilderIntro from '../builderIntro';
import MoreSoon from '../moreSoon';
import './style.css';

export default function Templates({}) {
 return (
  <div>
      <LandingHeader />
      <div className='templates-box' />
      <BuilderIntro />
      <MoreSoon />   
  </div>
 )
}