import 'bootstrap/dist/css/bootstrap.css';
import _ from 'lodash';
import { InputGroup, Form, Col, Row, Modal, Card, Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import skillOptions from '../../skillOptions';
import { Link } from 'react-router-dom';
import JSZip from 'jszip';

import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import Header from '../../components/Header'
import AboutMe from '../../components/AboutMe';
import Intro from '../../components/Intro';
import IntroPreview from '../../components/IntroPreview';
import Skills from '../../components/Skills';
import Experience from '../../components/Experience';
import Applications from '../../components/Applications';
import Education from '../../components/Education';
import Contact from '../../components/Contact';
import IntroInput from '../../components/IntroInput';
import SkillInput from '../../components/SkillInput';
import DownloadInput from '../../components/DownloadInput';
import MenuNavBar from '../../components/MenuNavBar';
import ExperienceInput from '../../components/ExperienceInput';
import EducationInput from '../../components/EducationInput';
import ContactInput from '../../components/ContactInput';
import ApplicationsInput from '../../components/ApplicationsInput';

import '../../css/bootstrap-override.css';
import '../../css/devicon-override.css';
import '../../css/footer.css';
import '../../css/main.css';
import '../../css/screen-timer.css';
import './style.css';
import MobileView from '../mobileView';
import BuilderModal from '../../components/BuilderModal';

function Builder({ defaultConfig }) {

  window.onbeforeunload = function () {
    return "Are you sure you want to leave? BEEP";
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);

  const [config, updateConfig] = useState(defaultConfig)
  const [showTabletInput, setShowTabletInput] = useState(false);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  const formatContact = (config) => {

    const newConfig = _.cloneDeep(config);
    const newContact = config.contact;

    const contactArray = []

    if (!_.isEmpty(newContact.contact_email)) {
      contactArray.push({
        label: newContact.contact_email,
        url: newContact.contact_email,
        key: 'contact_email'
      })
    }

    if (!_.isEmpty(newContact.contact_phone)) {
      contactArray.push({
        label: newContact.contact_phone,
        url: newContact.contact_phone,
        key: 'contact_phone'
      })
    }

    if (!_.isEmpty(newContact.contact_link_1)) {
      contactArray.push({
        label: newContact.contact_link_1_label,
        url: newContact.contact_link_1,
        key: 'contact_link_1'
      })
    }

    if (!_.isEmpty(newContact.contact_link_2)) {
      contactArray.push({
        label: newContact.contact_link_2_label,
        url: newContact.contact_link_2,
        key: 'contact_link_2'
      })
    }

    if (!_.isEmpty(newContact.contact_link_3)) {
      contactArray.push({
        label: newContact.contact_link_3_label,
        url: newContact.contact_link_3,
        key: 'contact_link_3'
      })
    }

    newConfig.contact = contactArray

    return newConfig

  }

  const parseContactData = (data) => {
    const newData = _.cloneDeep(data)
    let newContact = newData.contact
    let parsedContact = {}
    _.each(newContact, c => {

      if (c.key === 'contact_email') {
        parsedContact['contact_email'] = c.url
      }

      if (c.key === 'contact_phone') {
        parsedContact['contact_phone'] = c.url
      }

      if (c.key === 'contact_link_1') {
        parsedContact['contact_link_1'] = c.url
        parsedContact['contact_link_1_label'] = c.label

      }

      if (c.key === 'contact_link_2') {
        parsedContact['contact_link_2'] = c.url
        parsedContact['contact_link_2_label'] = c.label
      }

      if (c.key === 'contact_link_3') {
        parsedContact['contact_link_3'] = c.url
        parsedContact['contact_link_3_label'] = c.label
      }
    })

    newData.contact = parsedContact

    return newData;
  }

  const formatAndDownloadImages = config => {
    const backgrounds = config.backgrounds
    let imgObj = {
      avatar_image_url: backgrounds.avatar_image_url,
      education_background_image_url: backgrounds.education_background_image_url,
      education_institution_avatar_image_url: backgrounds.education_institution_avatar_image_url,
      intro_background_image_1: backgrounds.intro_background_image_1,
      intro_background_image_2: backgrounds.intro_background_image_2,
      intro_background_image_3: backgrounds.intro_background_image_3
    }

    _.each(config.applications, (app, index) => {
      imgObj[`application_${index + 1}_background_image_url`] = app.application_background_image_url
      imgObj[`application_${index + 1}_avatar`] = app.application_avatar
    })

    _.each(config.experience, (exp, index) => {
      imgObj[`workplace_${index + 1}_avatar_image_url`] = exp.workplace_avatar_image_url
    })


    downloadImagesAsZip(imgObj)

  }

  const handleDownload = () => {

    let downloadConfig = _.cloneDeep(config)

    formatAndDownloadImages(config)

    downloadConfig = formatContact(downloadConfig)

    const jsonString = JSON.stringify(downloadConfig, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'config.json';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const [fileContent, setFileContent] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setFileContent(content);
        try {
          let jsonData = JSON.parse(content);

          jsonData = parseContactData(jsonData)

          updateConfig(jsonData)

        } catch (error) {
          alert('Please upload a JSON file.')
        }
      };

      reader.readAsText(file);
    }
  };

  async function downloadImagesAsZip(imageUrls) {
    const zip = new JSZip();
    const folderName = 'horizon_background_images'; // Name of the folder inside the ZIP

    // Function to fetch and add image to ZIP
    async function addImageToZip(url, filename) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}. Status: ${response.status}`);
        }
        const blob = await response.blob();
        zip.folder(folderName).file(filename, blob);
      } catch (error) {
        console.error(`Error fetching ${url}:`, error);
      }
    }



    await Promise.all(_.map(imageUrls, (url, key) => {
      return addImageToZip(url, `${key}.png`)
    }
    ))


    // Add each image to the ZIP
    // await Promise.all(imageUrls.map((url, index) => addImageToZip(url, `image_${index + 1}.jpg`)));

    // Generate the ZIP file
    zip.generateAsync({ type: 'blob' })
      .then((blob) => {
        // Trigger the download
        const zipFilename = 'images.zip';
        if (window.navigator.msSaveOrOpenBlob) {
          // For IE and Edge
          window.navigator.msSaveOrOpenBlob(blob, zipFilename);
        } else {
          // For other browsers
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = zipFilename;
          link.click();
        }
      })
      .catch((error) => console.error('Error generating ZIP:', error));
  }







  const changeConfig = changedData => {
    updateConfig({
      ...config,
      ...changedData
    })
  }

  const changeText = changedData => {
    updateConfig({
      ...config,
      text: {
        ...config.text,
        ...changedData
      }
    })
  }

  const changeEducation = changedData => {
    updateConfig({
      ...config,
      education: {
        ...config.education,
        ...changedData
      }
    })
  }

  const changeBackgrounds = changedData => {
    updateConfig({
      ...config,
      backgrounds: {
        ...config.backgrounds,
        ...changedData
      }
    })
  }

  const changeContact = changedData => {
    updateConfig({
      ...config,
      contact: {
        ...config.contact,
        ...changedData
      }
    })
  }

  const [activeTab, setTab] = useState('intro')
  const [menuHeader, setMenuHeader] = useState('Edit Intro')


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      setShowTabletInput(true)
    }
    const timer = setTimeout(() => setShowModal(true), 1000); // Delay of 1000 milliseconds (1 second)
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const handleClose = () => setShowModal(false);

  const renderInputs = () => {

    switch (activeTab) {
      case ('intro'): {
        return <IntroInput config={config} changeConfig={changeConfig} changeText={changeText} changeBackgrounds={changeBackgrounds} />
      };
      case ('skills'): {
        return <SkillInput config={config} changeConfig={changeConfig} changeText={changeText} updateConfig={updateConfig} skillOptions={skillOptions} existingSkills={config.skills} changeBackgrounds={changeBackgrounds} />
      };
      case ('experience'): {
        return <ExperienceInput config={config} changeConfig={changeConfig} changeText={changeText} changeBackgrounds={changeBackgrounds} updateConfig={updateConfig} existingExperience={config.experience} skillOptions={skillOptions} />
      };
      case ('applications'): {
        return <ApplicationsInput config={config} changeConfig={changeConfig} changeBackgrounds={changeBackgrounds} changeText={changeText} updateConfig={updateConfig} existingApps={config.applications} />
      };
      case ('education'): {
        return <EducationInput config={config} changeEducation={changeEducation} changeBackgrounds={changeBackgrounds} changeText={changeText} changeConfig={changeConfig} />
      };
      case ('contact'): {
        return <ContactInput config={config} changeContact={changeContact} changeText={changeText} changeBackgrounds={changeBackgrounds} changeConfig={changeConfig} />
      };
      case ('download'): {
        return <DownloadInput handleDownload={() => handleDownload()} handleFileChange={() => handleFileChange()} />
      };
    }
  }

  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  let customWidth = '100%';
  let headerWidth = null;

  if (isMobile) {
    customWidth = '552px'
    headerWidth = '576px'
  } else if (isTablet) {
    customWidth = '700px'
    headerWidth = '724px'
  }

  const runAnimation = (id, offset, anime) => {

    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    var controller = new ScrollMagic.Controller();

    var scene = new ScrollMagic.Scene({
      triggerElement: id,
      offset: offset,
      reverse: false
    })
      .setTween(id, 0.5, anime)
      .addTo(controller);

  }

  useEffect(() => {

    // title and subtitles

    runAnimation('#skills-white-bar', -200, { width: '100%', opacity: 1 })
    runAnimation('#applications-white-bar', -200, { width: '100%', opacity: 1 })
    runAnimation('#education-white-bar', -200, { width: '100%', opacity: 1 })
    runAnimation('#contact-white-bar', -500, { width: '100%', opacity: 1 })
    runAnimation('#experience-white-bar', -200, { width: '100%', opacity: 1 })
    runAnimation('#skills-subtitle-slide-up', -200, { y: 0, opacity: 1 })
    runAnimation('#experience-subtitle-slide-up', -200, { y: 0, opacity: 1 })
    runAnimation('#education-subtitle-slide-up', -200, { y: 0, opacity: 1 })
    runAnimation('#applications-subtitle-slide-up', -200, { y: 0, opacity: 1 })
    runAnimation('#contact-subtitle-slide-up', -500, { y: 0, opacity: 1 })


    // applications

    for (var i = 0; i < config.applications.length; i++) {
      runAnimation(`#application-${i}-title-slide-left`, -200, { opacity: 1, x: 0 });
      runAnimation(`#application-${i}-desc-slide-right`, -200, { opacity: 1, x: 0 });
    }



    _.each(config.experience, (job, index) => {
      const technologies = job.technologies_used
      let numberOfTechnologies = technologies.length;
      for (let i = 0; i < numberOfTechnologies; i++) {
        const id = `#technology-${i}-in-${index}`


        let anime = { opacity: 1, duration: 20, delay: i * .1 }

        runAnimation(id, -200, anime)
      }

    })




  }, [])


  return (
    <div>
            <BuilderModal showModal={showModal} handleClose={handleClose} updateConfig={updateConfig} />





    {/* MOBILE */}

          <Row className='nopadding show-on-700'>
            {showTabletInput ?
            <Col xs={6} />            
          :
          <div />
          }
            {showTabletInput ?
                        <Col xs={12} style={{ position: 'fixed', zIndex: 9001 }}>
                        <Row style={{ backgroundColor: '#F9F9F9', height: '100vh' }}>
                          <MenuNavBar type='mobile' mobile={true} showTabletInput={showTabletInput} laptop={false} col={2} activeTab={activeTab} setTab={setTab} setMenuHeader={setMenuHeader} setShowTabletInput={setShowTabletInput} />
                          <Col xs={10} style={{ paddingTop: '15px', overflow: 'auto', height: '100%' }}>
                            {renderInputs()}
                          </Col>
                        </Row>
                      </Col>
          :
          <Row style={{zIndex: 1000, position: 'fixed' }}>
         <MenuNavBar type='mobile' mobile={true} showTabletInput={showTabletInput}  laptop={true} col={1} activeTab={activeTab} setTab={setTab} setMenuHeader={setMenuHeader} setShowTabletInput={setShowTabletInput} />
            </Row>
          }

            <div className='nopadding' style={{ width: showTabletInput ? '50vw' : '100vw', position: 'fixed', top: '0px', zIndex: 9000 }}>
                  <Header config={config} isMobile={true} type='mobile' />
                </div>
                <Row className='nopadding'>
                <div id="intro-mobile" className="intro--scroll-to-point" />
                  <IntroPreview config={config} isTablet={true} />
                  <div id='about-me-skills-gradient' style={{ background: `linear-gradient(transparent, ${config.backgrounds.about_me_skills_background_color})` }}>
                    <AboutMe config={config} isTablet={true} />
                    <div id="skills-mobile" className="skills--scroll-to-point" />
                    <Skills config={config} isTablet={true} />
                  </div>
                  <div id="experience-mobile" className="experience--scroll-to-point" />
                  <Experience isTablet={true} config={config} skillOptions={skillOptions} preview={false} />
                  <div id="applications-mobile" className="applications--scroll-to-point" />
                  <Applications isTablet={true} config={config} preview={false} />
                  <div id="education-mobile" className="education--scroll-to-point" />
                  <Education config={config} isTablet={true} />
                  <div id="contact-mobile" className="contact--scroll-to-point" />
                  <Contact config={config} isTablet={true} />
                </Row>
      
          </Row>








    {/* TABLET */}

  
          <Row className='nopadding show-on-1024 hide-on-700'>
            {showTabletInput ?
            <Col xs={6} />            
          :
          <div />
          }
            {showTabletInput ?
                        <Col xs={6} style={{ position: 'fixed', zIndex: 1000 }}>
                        <Row style={{ backgroundColor: '#F9F9F9', height: '100vh' }}>
                          <MenuNavBar showTabletInput={showTabletInput} laptop={true} type='tablet' col={2} activeTab={activeTab} setTab={setTab} setMenuHeader={setMenuHeader} setShowTabletInput={setShowTabletInput} />
                          <Col xs={10} style={{ paddingTop: '15px', overflow: 'auto', height: '100%' }}>
                            {renderInputs()}
                          </Col>
                        </Row>
                      </Col>
          :
          <Row style={{zIndex: 1000, position: 'fixed' }}>
         <MenuNavBar showTabletInput={showTabletInput} mobile={true} type='tablet' laptop={true} col={1} activeTab={activeTab} setTab={setTab} setMenuHeader={setMenuHeader} setShowTabletInput={setShowTabletInput} />
            </Row>
          }

          {showTabletInput ?
                                <div style={{ width: '50%', position: 'fixed', top: '0px', right: '0px', padding: '0px', zIndex: 9000 }}>
                                <Header config={config} isMobile={true} type='tablet' />
                              </div>
        :
        <div style={{ width: '100%', position: 'fixed', top: '0px', zIndex: 9000, padding: '0px' }}>
        <Header config={config} isMobile={true} type='tablet' />
      </div>
        }
            <Col xs={showTabletInput ? 6 : 12}>
              <div style={{ position: 'relative' }}>
                <Row>
                <div id="intro-tablet" className="intro--scroll-to-point" />
                  <Intro config={config} isTablet={true} />
                  <div id='about-me-skills-gradient' style={{ background: `linear-gradient(transparent, ${config.backgrounds.about_me_skills_background_color})` }}>
                    <AboutMe config={config} isTablet={true} />
                    <div id="skills-tablet" className="skills--scroll-to-point" />
                    <Skills config={config} isTablet={true} />
                  </div>
                  <div id="experience-tablet" className="experience--scroll-to-point" />
                  <Experience isTablet={true} config={config} skillOptions={skillOptions} preview={false} />
                  <div id="applications-tablet" className="applications--scroll-to-point" />
                  <Applications isTablet={true} config={config} preview={false} />
                  <div id="education-tablet" className="education--scroll-to-point" />
                  <Education config={config} isTablet={true} />
                  <div id="contact-tablet" className="contact--scroll-to-point" />
                  <Contact config={config} isTablet={true} />
                </Row>
              </div>
            </Col>
          </Row>
  
  









{/* LAPTOP */}
        <div className='hide-on-1024'>
  
          <div>
            <Row className='builder-header nopadding'>
              <Col xs={4}>
                <div>
                  <Link to='/'>
                    <div className='devhorizon-logo-black'>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col xs={8} className='d-flex toggle-button-container'>
                <ButtonGroup aria-label="Basic example">
                  <ToggleButton variant="outline-secondary" type='radio' checked={isMobile} onClick={() => {
                    setIsMobile(true)
                    setIsTablet(false)
                  }}>Mobile</ToggleButton>
                  <ToggleButton variant="outline-secondary" type='radio' checked={isTablet} onClick={() => {
                    setIsMobile(false)
                    setIsTablet(true)
                  }}>Tablet</ToggleButton>
                  <ToggleButton variant="outline-secondary" type='radio' checked={!isMobile && !isTablet} onClick={() => {
                    setIsMobile(false)
                    setIsTablet(false)
                  }}>Desktop</ToggleButton>
                </ButtonGroup>
                <div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <Button variant="secondary" style={{ marginRight: '15px' }} onClick={handleButtonClick}>
                    <div className='d-flex justify-content-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-upload" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                      <div style={{ marginLeft: '10px' }}>
                        Upload Config
                      </div>
                    </div>
                  </Button>
                  <Button variant='primary' onClick={() => handleDownload()}>
                    <div className='d-flex justify-content-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                      <div style={{ marginLeft: '10px' }}>
                        Download Config
                      </div>
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
            <div>
              <Row className='nopadding'>
                <Col xs={4} />
                <Col xs={4} style={{ position: 'fixed', top: '75px', zIndex: 1000 }}>
                  <Row style={{ backgroundColor: '#F9F9F9', height: '100vh', display: 'flex', justifyContent: 'space-between' }}>
                    <MenuNavBar type='laptop' activeTab={activeTab} setTab={setTab} setMenuHeader={setMenuHeader} setShowTabletInput={setShowTabletInput} />
                    <Col xs={10} style={{ paddingTop: '15px', overflow: 'auto', height: '100%' }}>
                      {renderInputs()}
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} className={(isMobile || isTablet) ? 'mobile-container' : ''}>
                  {(isMobile || isTablet) ?
                    <div className='nopadding' style={{ width: headerWidth, position: 'fixed', top: '75px', zIndex: 9000 }}>
                      <Header config={config} isMobile={isMobile} isTablet={isTablet} customWidth={customWidth} type='laptop' />
                    </div>
                    :
                    <Header config={config} isMobile={isMobile} isTablet={isTablet} customWidth={customWidth} />
                  }
                  <div className={(isMobile || isTablet) ? 'd-flex blockage-1' : 'd-none'} />
                  <div style={{ width: customWidth}}>
                    <Row>
                    <div id="intro-laptop" className="intro--scroll-to-point" />
                      <Intro config={config} isMobile={isMobile} isTablet={isTablet} />
                      <div id='about-me-skills-gradient' style={{ background: `linear-gradient(transparent, ${config.backgrounds.about_me_skills_background_color})` }}>
                        <AboutMe config={config} isMobile={isMobile} isTablet={isTablet} />
                        <div id="skills-laptop" className="skills--scroll-to-point" />
                        <Skills config={config} isMobile={isMobile} isTablet={isTablet} />
                      </div>
                      <div id="experience-laptop" className="experience--scroll-to-point" />
                      <Experience isMobile={isMobile} isTablet={isTablet} config={config} skillOptions={skillOptions} preview={false} />
                      <div id="applications-laptop" className="applications--scroll-to-point" />
                      <Applications isMobile={isMobile} isTablet={isTablet} config={config} preview={false} />
                      <div id="education-laptop" className="education--scroll-to-point" />
                      <Education config={config} isMobile={isMobile} isTablet={isTablet} />
                      <div id="contact-laptop" className="contact--scroll-to-point" />
                      <Contact config={config} isMobile={isMobile} isTablet={isTablet} />
                    </Row>
                  </div>
                  <div className={(isMobile || isTablet) ? 'd-flex blockage-2' : 'd-none'} />
                </Col>
              </Row>
            </div>
          </div>
        </div>





    </div>
  );
}

export default Builder;