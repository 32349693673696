import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color'
import { TextField } from '@mui/material';
import './style.css';

function AddTechModal({ config, updateConfig, showAddTech, handleCloseAddTech, existingTech, experienceIndex }) {

  const emptyTechState = {
    label: 'New Skill',
    value: 'New Skill',
    title: 'New Skill',
    color: 'rgb(13,110,251)',
    labelColor: '#ffffff'
  }

  const [currentTech, updateCurrentTech] = useState(emptyTechState)

  const [openAddTechColor, setOpenAddTechColor] = useState(false);
  const [openAddLabelColor, setOpenAddLabelColor] = useState(false);

  const { title, color, labelColor } = currentTech;

  const handleClose = () => {
    updateCurrentTech(emptyTechState);
    handleCloseAddTech();
    setOpenAddTechColor(false);
    setOpenAddLabelColor(false);
  }

  const handleAddTech = () => {


    const newTech = _.cloneDeep(existingTech);
    const newConfig = _.cloneDeep(config);
    newConfig.experience[experienceIndex].technologies_used = newTech;
    newTech.push(currentTech)

    updateConfig(newConfig)
    updateCurrentTech(emptyTechState);
    handleCloseAddTech();
    setOpenAddTechColor(false);
    setOpenAddLabelColor(false);
  }

  return (
    <>
      <Modal show={showAddTech} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Technology</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            If your desired technology is not available in the "Technologies" dropdown menu, you can add your own here!
          </div>
          <Row className='demo-tech-container mt-5 mb-5'>

            <div class="experience--language-on" style={{ backgroundColor: color, color: labelColor }}>
              {title}
            </div>


          </Row>
          <Row className='mt-3'>
            <Col xs={3}>

            </Col>
            <Col xs={6}>
              <Row>
                <TextField
                  label='Technology Name'
                  style={{ width: '100%' }}
                  value={currentTech.title}
                  onChange={e => updateCurrentTech({
                    ...currentTech,
                    label: e.target.value,
                    value: e.target.value,
                    title: e.target.value
                  })}
                />
              </Row>
              <Row className='mt-3' style={{height: '56px'}}>
                <TextField
                  label='Technology Background Color'
                  style={{ width: '100%' }}
                  value={currentTech.color}
                  onChange={e => updateCurrentTech({
                    ...currentTech,
                    color: e.target.value,
                  })}
                />
                                <div className='add-skill-color-collapse-container'>
                  <div className='color-collapse' style={{ backgroundColor: currentTech.color }} onClick={() => {
                    setOpenAddTechColor(!openAddTechColor)
                  }}>
                  </div>
                </div>
                <div className={openAddTechColor ? 'add-tech-color-container' : 'add-tech-color-container-hide'}>
                  <SketchPicker color={currentTech.color} onChange={(e) => {
                    updateCurrentTech({
                      ...currentTech,
                      color: e.hex,
                    })
                  }} />
                </div>
              </Row>
              <Row className='mt-3' style={{height: '56px'}}>
                <TextField
                  label='Technology Label Color'
                  style={{ width: '100%' }}
                  value={currentTech.labelColor}
                  onChange={e => updateCurrentTech({
                    ...currentTech,
                    labelColor: e.target.value,
                  })}
                />
                                                <div className='add-skill-color-collapse-container'>
                  <div className='color-collapse' style={{ backgroundColor: currentTech.labelColor }} onClick={() => {
                    setOpenAddLabelColor(!openAddLabelColor)
                  }}>
                  </div>
                </div>
                <div className={openAddLabelColor ? 'add-label-color-container' : 'add-label-color-container-hide'}>
                  <SketchPicker color={currentTech.labelColor} onChange={(e) => {
                    updateCurrentTech({
                      ...currentTech,
                      labelColor: e.hex,
                    })
                  }} />
                </div>
              </Row>
            </Col>
            <Col xs={3}>

            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTech}>
            Add Technology
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTechModal;