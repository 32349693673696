import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';

export default function AboutUsPage() {

 return (
  <Container fluid className='steps-container' id='aboutus'>
   <Row className='nopadding' style={{ height: '100%', width: '100%' }}>
   <Col xs={0} sm={1} md={1} lg={2} xl={3} />
    <Col className='about-us-page--container' xs={12} sm={10} md={10} lg={8} xl={6}>
      <div className='about-us-page--box'>
      <div className='aboutus--header'>
    About Devhorizons
   </div>
   <div className='about-us-page--bubble-container'>
   <div className='about-us-page--bubble' />
   </div>
   <div className='aboutus--text-container'>
   <div className='aboutus--desc'>
    My name is Logan Smith, and I have been a web developer since 2018. One morning while I was browsing LinkedIn and finishing my second cup of coffee, I noticed that many tech job applications ask for a portfolio website URL. Understanding how time consuming and stressful a job hunt can be, I set out to create a solution for fellow software engineers wanting an eye-catching portfolio website that highlights their careers and sets them apart from other candidates. 
   </div>
   <div className='aboutus--desc'>
    Landing a tech job can be a challenge. Trust me, I know. I sincerely hope that Devhorizons not only saves you dozens of hours, but also lends you a hand in landing that job!
   </div>
   <div className='aboutus--desc' style={{marginBottom: '75px'}}>
    Feel free to reach out to me at <a style={{ color: '#3366CC' }} href='mailto:ljswebdev@gmail.com'>ljswebdev@gmail.com</a> or connect with me on <a style={{ color: '#3366CC' }} target='_blank' href='https://www.linkedin.com/in/logansmith91/'>LinkedIn</a>
   </div>
      </div>
      </div>
     </Col>
    <Col xs={0} sm={1} md={1} lg={2} xl={3} />
   </Row>
  </Container>
 )
}