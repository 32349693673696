import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PurchaseModal from '../PurchaseModal';
import './style.css'

export default function PreviewHeader() {

 const [menu, openMenu] = useState(true)
 const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

 return (
  <div className='preview-header-container'>
     <PurchaseModal purchaseModalClose={() => setPurchaseModalOpen(false)} purchaseModalOpen={purchaseModalOpen} />
  <div>
  <Link to='/'>
    <div className='devhorizon-logo-white'>
    
    </div>
    </Link>
  </div>
   <div className='preview-header-menu-container'>
    <div className='preview-header-menu-item-you-are-currently'>You are currently previewing the Horizon template theme.</div>
    <div className='preview-header-menu-item'>
     <Link to='/web-developer-portfolio/builder/horizon'>
     <Button variant='primary'>
      Start Building
     </Button>
     </Link>
    </div>
    <div className='preview-header-menu-item'>
     <Button variant='success' onClick={() => setPurchaseModalOpen(true)}>
      Purchase Template
     </Button>
    </div>
    <div className='landing-mobile-menu-burger-container' onClick={() => openMenu(!menu)}>
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
      </div>
      {menu ? null :
        <div className='preview-header-mobile-menu'>
                          <div className='landing-mobile-menu-option' style={{height: '15px'}} />
          <div className='landing-mobile-menu-option'>
          <Link to='/web-developer-portfolio/builder/horizon'>
     <Button variant='primary'>
      Start Building
     </Button>
     </Link>
          </div>
          <div className='landing-mobile-menu-option' style={{height: '15px'}} />
          <div className='landing-mobile-menu-option'>
          <Button variant='success' onClick={() => setPurchaseModalOpen(true)}>
      Purchase Template
     </Button>
          </div>
          <div className='landing-mobile-menu-option' style={{height: '15px'}} />
     
        </div>}
   </div>
  </div>
 )
}
