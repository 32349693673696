import _ from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color'
import { Form, Col, Row, Button, Card } from 'react-bootstrap';
import { TextField } from '@mui/material';
import Select from 'react-select';
import AddSkillModal from '../AddSkillModal';
import './style.css';

export default function IntroInput({ config, changeConfig, updateConfig, skillOptions, existingSkills, changeText, changeBackgrounds }) {

  const removeSkill = e => {
    const selectedSkills = _.cloneDeep(config.skills);
    _.remove(selectedSkills, f => f.value === e.value);
    updateConfig({
      ...config,
      skills: selectedSkills
    })
  }

  const [showAddSkill, setShowAddSkill] = useState(false);

  const handleCloseAddSkill = () => setShowAddSkill(false);
  const handleShowAddSkill = () => setShowAddSkill(true);

  const [openSkillsBackgroundColor, setOpenSkillsBackgroundColor] = useState(false);


  const bigUpdate = skills => {
    changeConfig({ skills: skills })
  }

  const moveItemUp = (index) => {
    if (index > 0) {
      const itemToMove = existingSkills[index];
      existingSkills.splice(index, 1);
      existingSkills.splice(index - 1, 0, itemToMove);
      bigUpdate(existingSkills)
    }
  }

  const moveItemDown = (index) => {
    if (index < existingSkills.length - 1) {
      const itemToMove = existingSkills[index];
      existingSkills.splice(index, 1);
      existingSkills.splice(index + 1, 0, itemToMove);
      bigUpdate(existingSkills)
    }
  }

  const truncateString = (str, num) => {
    if (str.length > num) {
        return str.slice(0, num) + '...';
    }
    return str;
};

  return (
    <div>
          <Card style={{ paddingBottom: '150px' }} className='input-flash'>
      <Card.Header>
        Edit Skills
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className='config-input-container mb-3'>
              <TextField
                label="Skills Subtitle"
                style={{ width: '100%' }}
                value={config.text.skills_subtitle}
                onChange={e => changeText({ skills_subtitle: e.target.value })}
              />
            </div>
            <div className='config-input-container mb-3'>
              <div className='color-collapse-container'>
                <div className='color-collapse' style={{ backgroundColor: config.backgrounds.about_me_skills_background_color }} onClick={() => {
                  setOpenSkillsBackgroundColor(!openSkillsBackgroundColor)
                }}>
                </div>
              </div>
              <TextField
                label="Gradient Background Color"
                style={{ width: '100%' }}
                value={config.backgrounds.about_me_skills_background_color}
                onChange={e => changeBackgrounds({ about_me_skills_background_color: e.target.value })}
              />
              <div className={openSkillsBackgroundColor ? 'color-container' : 'color-container-hide'}>
                <SketchPicker color={config.backgrounds.about_me_skills_background_color} onChange={(e) => {
                  changeBackgrounds({ about_me_skills_background_color: e.hex })
                }} />
              </div>
            </div>
          </Col>
        </Row>
        <div className='config-input-container mb-3'>
          <Form.Label>
            Skills
          </Form.Label>
          <div style={{ width: '100%' }}>
            <Select
              placeholder="Select Skills"
              value={config.skills}
              isMulti={true}
              controlShouldRenderValue={false}
              onChange={e => changeConfig({ skills: e })}
              options={skillOptions}
            />
          </div>
          <Row style={{ width: '104%' }}>
            <div className='config-input-container mt-3' style={{ paddingRight: '0px' }}>
              <Row style={{ width: '100%', marginLeft: '0px' }}>
                <Col xs={12} style={{ paddingRight: '0px' }}>
                  {existingSkills.length !== 0 ? existingSkills.map((e, i) => {
                    return (
                      <div className='rearrange-row'>
                        <Row className='rearrange-block' style={{ backgroundColor: e.color, color: e.labelColor }}>
                          <div onClick={() => moveItemDown(i)} className='rearrange-left'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                            </svg>
                          </div>
                          {truncateString(e.label, 12)}
                          <div onClick={() => moveItemUp(i)} className='rearrange-right'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                            </svg>
                          </div>
                        </Row>
                        <div className='delete-skill' onClick={() => removeSkill(e)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#808080" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </div>
                      </div>
                    )
                  }) :
                    <div className='nothing'>
                      <div>
                        No skills selected.
                      </div>
                      <div>
                        Select from the dropdown menu above.
                      </div>
                    </div>
                  }
                </Col>
              </Row>
            </div>
          </Row>
        </div>
        <Row className='mt-3'>
          <Col xs={12} className='add-your-own-skill-container'>
            <Button variant="primary" onClick={() => handleShowAddSkill()} style={{ marginLeft: '15px' }}>
              Add Your Own Skill
            </Button>
          </Col>
        </Row>
        <AddSkillModal
          existingSkills={config.skills}
          bigUpdate={bigUpdate}
          showAddSkill={showAddSkill}
          handleCloseAddSkill={handleCloseAddSkill}
        />
      </Card.Body>
    </Card>
    </div>
  )
}