import React from 'react';
import { Row, Col } from 'react-bootstrap';

import './style.css';

export default function SkillsPreview({ config, isMobile, isTablet }) {

  // col-sm-0 col-md-1 col-lg-2 col-xl-3

  const renderSkills = () => {
    return config.skills.map((skill, id) => {
      const { title, color } = skill
        return (
        <div class="skills--skills-box">
        <div class="skills--skill-tab" style={{backgroundColor: color}}></div>
        <div class="skills--skill-tab-2" style={{backgroundColor: color}}></div>
        <div class="skills--skill-block">
          {title}
        </div>
        </div>
        )
    })
  }

  return (
    <Row className="skills--container nopadding" id='skills-trigger'>
      <Row className='nopadding'>

      <Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6} className='half-width-black-banner nopadding'>

        <h1>
      {config.text.header_skills_title}
      </h1>
      <div className="thick-white-bar-off" id='skills-white-bar'>
      </div>
      <h3 className="vertical-slide-animation" id="skills-subtitle-slide-up">
            {config.text.skills_subtitle}
          </h3>
        </Col>
        <Col sm={0} md={1} lg={2} xl={3} />
      </Row>
      <Row className="mt-5" style={{width: '100%'}}>
      <Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6}>
            <div className="skills--block-container">
              {renderSkills()}
          </div>
        </Col>
        <Col sm={0} md={1} lg={2} xl={3} />
      </Row>
    <div style={{marginBottom: '150px'}} />
  </Row>



  )
}

