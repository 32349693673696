import React from 'react';
import './style.css'

export default function LandingDesc({children}) {

  const desc2 = 'Our Portfolio Builder streamlines the process of creating your own professional portfolio website, allowing you to effortlessly communicate your technical expertise.'

  return (
    <div className='landing-desc-fade'>
      {desc2}
    </div>
  )

}