import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import './style.css';

export default function MoreSoon() {
  return (
    <Container fluid style={{backgroundColor: '#2e2e2e'}}>
      <Row className='nopadding'>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
        <Col xs={10} md={10} lg={8} xl={6} className='nopadding'>
    <div className='more-coming-soon'>
    More coming soon!
      </div>
        </Col>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
      </Row>
    </Container>
  )
}