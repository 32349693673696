import React from 'react'
import {Row, Col} from 'react-bootstrap';

export default function AboutMePreview({config, isMobile, isTablet}) {

  // col-sm-0 col-md-1 col-lg-2 col-xl-3

  return (

    <Row className='about-me--container nopadding'>
        <Col sm={0} md={1} lg={2} xl={3} />
        <Col sm={12} md={10} lg={8} xl={6} className='nopadding'>
        <div className='about-me--text-box'>
        <h1>{config.text.about_me_title}</h1>
        <div className="thick-white-bar">
        </div>
        <div className='about-me--desc-container'>
        {config.text.about_me}
        </div>
        </div>
      </Col>
      <Col xs={0} md={1} lg={2} xl={2}>
      </Col>
      <div style={{marginBottom: '150px'}} />
    </Row>
  )
}