import React from 'react';

import './intro-screen-1.css';
import './test-anime.css';

export default function Intro({ config, introClasses, isMobile, isTablet }) {

  let backgroundStyle = {}

  if (isMobile || isTablet) {

  }

  const introJobTitles = [
    config.text.intro_title_1,
    config.text.intro_title_2,
    config.text.intro_title_3
  ].filter(title => title); // Filter out undefined or empty titles
  
  const numberOfTitles = introJobTitles.length;


  return (
    <div className="row" id='intro'>
      <div className="intro--margin nopadding">
        <div className="intro--container">
          <div className={`intro--profile-bubble`} style={{backgroundImage: `url(${config.backgrounds.avatar_image_url})`}}>
          </div>
          <div className="half-width-black-banner" style={backgroundStyle}>
            <div className={`intro--header${(isMobile || isTablet) ? '-vert' : ''}`}>
            {config.text.intro_first_name} {config.text.intro_last_name}
            </div>
            <div className={`intro--subheader${(isMobile || isTablet) ? '-vert' : ''}`}>
              <div className={`intro--position-1${(isMobile || isTablet) ? '-vert' : ''}`}>
                {config.text.intro_title_1}
              </div>
              <div className={`intro--divider${(isMobile || isTablet) ? '-vert' : ''}`} style={{display: introJobTitles.length < 2 ? 'none' : 'flex'}}>
              </div>
              <div className={`intro--position-2${(isMobile || isTablet) ? '-vert' : ''}`}>
                {config.text.intro_title_2}
              </div>
              <div className={`intro--divider${(isMobile || isTablet) ? '-vert' : ''}`} style={{display: introJobTitles.length < 3 ? 'none' : 'flex'}}>
              </div>
              <div className={`intro--position-3${(isMobile || isTablet) ? '-vert' : ''}`}>
                {config.text.intro_title_3}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="screen-timer--container">
        <div className='spinner-1'></div>
        <div className='spinner-2'></div>
        <div className='spinner-3'></div>
      </div>
      <div className='anime-black'>
      </div>
      <div className={`test-anime-1 anime-background`} style={{backgroundImage: `url(${config.backgrounds.intro_background_image_1})`}}>
      </div>
      <div className={`test-anime-2 anime-background`} style={{backgroundImage: `url(${config.backgrounds.intro_background_image_2})`}}>
</div>
<div className={`test-anime-3 anime-background`} style={{backgroundImage: `url(${config.backgrounds.intro_background_image_3})`}}>
</div>
    </div>
  )
}