import React from 'react';
import {Row, Col} from 'react-bootstrap'

export default function ContactPreview({config}) {

  return (

    <Row className='contact--container nopadding' style={{background: `${config.backgrounds.contact_background_color}`}}>
      <Col xs={0} md={1} lg={2} xl={3} />

      <Col xs={12} md={10} lg={8} xl={6} style={{margin: '150px 0 150px 0'}}>
        <div className='contact--box'>
        <h1 id='contact'>
            {config.text.header_contact_title}
          </h1>
          <div id="contact-white-bar" className="thick-white-bar-off">
          </div>
          <h3 className="vertical-slide-animation" id="contact-subtitle-slide-up">
            {config.text.contact_subtitle}
          </h3>
        <div className="contact--card">
          <div className="contact--contact-method">
            {config.contact.contact_email}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_phone}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_1_label}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_2_label}
          </div>
          <div className="contact--contact-method">
            {config.contact.contact_link_3_label}
          </div>
        </div>
        </div>
      </Col>

      <Col xs={0} md={1} lg={2} xl={3} />
    </Row>


  )
}