import React from 'react';

export default function IntroPreviewDemo({ config, introClasses, isMobile, isTablet }) {

  let backgroundStyle = {}

  if (isMobile || isTablet) {
    backgroundStyle = {  background: 'rgb(20, 20, 20, .7)'}
  }

  return (
    <div className="row nopadding" id='intro'>
      <div className='col-sm-0 col-md-1 col-lg-2 col-xl-3' />
      <div className="intro--margin col-sm-12 col-md-10 col-lg-8 col-xl-6 nopadding">
        <div className="intro--container">
        <div className={`intro--profile-bubble ${config.backgrounds.avatar_image_url}`}>
          </div>
          <div className="half-width-black-banner" style={backgroundStyle}>
            <div className="intro--header">
            {config.text.intro_first_name} {config.text.intro_last_name}
            </div>
            <div className="intro--subheader">
              <div className="intro--position-1">
                {config.text.intro_title_1}
              </div>
              <div className="intro--divider">
              </div>
              <div className="intro--position-2">
                {config.text.intro_title_2}
              </div>
              <div className="intro--divider">
              </div>
              <div className="intro--position-3">
                {config.text.intro_title_3}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-0 col-md-1 col-lg-2 col-xl-3' />
      <div className='anime-black'>
      </div>
      <div className={`test-anime-1 anime-background ${config.backgrounds.intro_background_image_1}`}>
      </div>
      <div className={`test-anime-2 anime-background ${config.backgrounds.intro_background_image_2}`}>
</div>
<div className={`test-anime-3 anime-background ${config.backgrounds.intro_background_image_3}`}>
</div>
<div className="screen-timer--container">
        <div className='spinner-1'></div>
        <div className='spinner-2'></div>
        <div className='spinner-3'></div>
      </div>
    </div>
  )
}



