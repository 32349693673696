import React from 'react';
import LandingIntro from '../landingIntro';
import {Container, Row, Col} from 'react-bootstrap'
import BuilderIntro from '../builderIntro';
import BuilderIntroHeader from '../builderIntroHeader';
import Steps from '../steps';
import Squares from '../squares';
import MoreSoon from '../moreSoon';
import LaptopView from '../../components/LaptopView';
import AboutUs from '../aboutUs';
import SlidingHeader from '../../components/SlidingHeader';
import Pricing from '../pricing';
import './style.css';

export default function Home() {

  return (
    <Container fluid className='main-container'>
      <SlidingHeader />
      <BuilderIntroHeader />
      <LandingIntro />
      <LaptopView />
        <BuilderIntro />
        <MoreSoon />   
        <Steps />
       <Squares />
       <Pricing />
       <AboutUs />
    </Container>
  )
}