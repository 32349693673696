import React, {useState} from 'react'
import { Modal, Container, Button, Row, Col} from 'react-bootstrap' 
import samplePreset from '../../presetConfigs/samplePreset.json'
import blankPreset from '../../presetConfigs/blankPreset.json'
import johnPreset from '../../presetConfigs/johnPreset.json'
import jessicaPreset from '../../presetConfigs/jessicaPreset.json'
import './style.css';

export default function BuilderModal({showModal, handleClose, updateConfig}) {

  const [active, setActive] = useState('blank')

  const changePreset = preset => {
    setActive(preset)
    if (preset === 'blank') {
      updateConfig(blankPreset)
    } else if (preset === 'sample') {
      updateConfig(samplePreset)
    } else if (preset === 'jessica') {
      updateConfig(jessicaPreset)
    } else if (preset === 'john') {
      updateConfig(johnPreset)
    }
  }

 return (
  <Modal className='hide-builder-modal-on-mobile' show={showModal} onHide={handleClose} backdrop="static" size='lg' keyboard={false} style={{height: '100%', zIndex: 90000}}>
  <Modal.Header closeButton>
    <Modal.Title>Welcome to the Portfolio Builder!</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{height: 'auto'}}>
    <div>The Portfolio Builder enables you to design and customize the Horizon template to your liking.</div>
    <div style={{marginTop: '15px'}}>As you design, the Horizon template will reflect your changes in real time.</div>
    <div style={{marginTop: '15px'}}>To get started quickly, select a preset to begin working from -</div>
    <Container style={{paddingBottom: '30px'}}>
    <div className='builder-modal--container' style={{marginTop: '15px'}}>
      <div className='builder-modal--preset-container' onClick={() => changePreset('blank')}>
      <div className='builder-modal--box empty'></div>
        <div className={`builder-modal--bottom ${active === 'blank' ? 'builder-modal--active' : 'builder-modal--inactive'}`}>
  Blank
        </div>
      </div>
      <div className='builder-modal--preset-container' onClick={() => changePreset('sample')}>
      <div className='builder-modal--box sample-preset'></div>
      <div className={`builder-modal--bottom ${active === 'sample' ? 'builder-modal--active' : 'builder-modal--inactive'}`}>
Sample
        </div>
      </div>
    </div>
    <div className='builder-modal--container' style={{marginTop: '45px'}}>
      <div className='builder-modal--preset-container' onClick={() => changePreset('jessica')}>
      <div className='builder-modal--box jessica-preset'></div>
      <div className={`builder-modal--bottom ${active === 'jessica' ? 'builder-modal--active' : 'builder-modal--inactive'}`}>
Jessica
        </div>
      </div>
      <div className='builder-modal--preset-container' onClick={() => changePreset('john')}>
      <div className='builder-modal--box john-preset'></div>
      <div className={`builder-modal--bottom ${active === 'john' ? 'builder-modal--active' : 'builder-modal--inactive'}`}>
  John
        </div>
      </div>
    </div>


    </Container>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleClose}>
      Start Building
    </Button>
  </Modal.Footer>
</Modal>
 )
}