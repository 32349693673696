import React, { useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import PurchaseModal from '../../components/PurchaseModal';
import './style.css'
import { Link } from 'react-router-dom';

export default function Pricing({ }) {

  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

  return (
    <Container fluid className='pricing-container'>
                 <PurchaseModal purchaseModalClose={() => setPurchaseModalOpen(false)} purchaseModalOpen={purchaseModalOpen} />
      <div className='pricing-jump-to-point' id='pricing' />
      {/* Laptop View */}
      <Row className='pricing-laptop nopadding'>
        <div className='pricing-title'>
          Pricing
        </div>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
        <Col xs={10} md={10} lg={8} xl={6} style={{ borderTop: '1px solid white', borderBottom: '1px solid white', padding: '45px 0 45px 0' }}>
          <Row>
            <Col xs={6}>
            <div className='pricing-square-left-container'>
            <div className='pricing-triangle-left' />
            <div className='pricing-icon-container'>
              $12.99
            </div>
            <div className='pricing-triangle-right' />
            <div className='pricing-desc-container'>
              <div className='pricing-square-desc-title'>
                Single Template Download
              </div>
              <div className='pricing-square-desc'>
                <ul>
                  <li>A simple yet sophisticated readymade portfolio website ready to host!</li>
                  <li>Once you buy a Devhorizons template, you can fully customize it at any time via the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>.</li>
                  <li>The Portfolio Builder is free to use, as it exports the config.json file needed to power the template.</li>
                  <li>Quick and easy download and checkout powered by Wetransfer!</li>
                </ul>
                <div className='pricing-button-container' onClick={() => setPurchaseModalOpen(true)}>
                  <Button variant='success'>Purchase Template</Button>
                </div>
              </div>
            </div>
          </div>
            </Col>
            <Col xs={6}>
            <div className='pricing-square-left-container'>

<div className='pricing-icon-container'>
  $80
</div>

<div className='pricing-desc-container'>
  <div className='pricing-square-desc-title'>
    Custom Portfolio Website Service
  </div>
  <div className='pricing-square-desc'>
    <ul>
      <li>You can hire Devhorizons to create your portfolio for you.</li>
      <li>We will ask a few questions and have your template complete within 1-3 days.</li>
      <li>You can always edit and make changes to your template via the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>.</li>
      <li>Powered by Fiverr!</li>
    </ul>
    <div className='pricing-button-container'>
      <a href='https://www.fiverr.com/devhorizons/create-your-web-development-portfolio-website'>
      <Button variant='primary'>Learn More</Button>
      </a>
    </div>
  </div>
</div>
</div>              
              </Col>
          </Row>

        </Col>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
        <div className='pricing-title'>

        </div>
      </Row>
      {/* Mobile view */}

      <Row className='pricing-mobile nopadding'>
        <div className='pricing-title'>
          Pricing
        </div>
        <Col xs={0} md={1} lg={2} xl={3} className='nopadding' />
        <Col xs={12} md={10} lg={8} xl={6} style={{ borderTop: '1px solid white', borderBottom: '1px solid white', padding: '45px 0 45px 0' }}>

            <div className='pricing-square-left-container'>
            <div className='pricing-triangle-left' />
            <div className='pricing-icon-container'>
              $12.99
            </div>
            <div className='pricing-triangle-right' />
            <div className='pricing-desc-container'>
              <div className='pricing-square-desc-title'>
                Single Template Download
              </div>
              <div className='pricing-square-desc'>
                <ul>
                  <li>A simple yet sophisticated readymade portfolio website ready to host!</li>
                  <li>Once you buy a Devhorizons template, you can fully customize it at any time via the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>.</li>
                  <li>The Portfolio Builder is free to use, as it exports the config.json file needed to power the template.</li>
                  <li>Quick and easy download and checkout powered by Wetransfer!</li>
                </ul>
                <div className='pricing-button-container' onClick={() => setPurchaseModalOpen(true)}>
                  <Button variant='success'>Purchase Template</Button>
                </div>
              </div>
            </div>
          </div>

            <div className='pricing-square-left-container'>

<div className='pricing-icon-container'>
  $80
</div>

<div className='pricing-desc-container'>
  <div className='pricing-square-desc-title'>
    Custom Portfolio Website Service
  </div>
  <div className='pricing-square-desc'>
    <ul>
      <li>Powered by Fiverr!</li>
      <li>You can hire Devhorizons to create your portfolio for you.</li>
      <li>We will ask a few questions and have your template complete within 1-3 days.</li>
      <li>You can always edit and make changes to your template via the <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link>.</li>
    </ul>
    <div className='pricing-button-container'>
    <a href='https://www.fiverr.com/devhorizons/create-your-web-development-portfolio-website'>
      <Button variant='primary'>Learn More</Button>
      </a>
    </div>
  </div>
</div>
</div>              
       
        </Col>
        <Col xs={0} md={1} lg={2} xl={3} className='nopadding' />
        <div className='pricing-title'>

        </div>
      </Row>



    </Container>
  )
}