import React from 'react'
import { Col, Row } from 'react-bootstrap';
import './style.css';

export default function ApplicationsPreview({config, isMobile, isTablet, preview}) {

  let sideWidths = 3;
  let mainWidth = 6

  if (isMobile) {
    sideWidths = 0;
    mainWidth = 12
  } else if (isTablet) {
    sideWidths = 1;
    mainWidth = 10
  }

  return (
    <Row className="applications--container nopadding" style={{background: `${config.backgrounds.applications_background_color}`}}>
    <Col xs={sideWidths}>
    </Col>
    <Col xs={mainWidth} className="applications--application-container">
      <div className="full-width-banner">
          <h1 id='applications'>
            {config.text.header_applications_title}
          </h1>
          <div id="applications-white-bar" className="thick-white-bar-off">
          </div>
          <h3 className="vertical-slide-animation" id="applications-subtitle-slide-up">
            {config.text.applications_subtitle}
          </h3>
      </div>

{config.applications.map((e, i) => {
  return (
    <div className="applications--box col-12 nopadding">
    <div className={`applications--inner-box`} style={{backgroundImage: `url(${e.application_background_image_url})`}}>
      <div className={`applications--logo company-1-logo`} style={{backgroundImage: `url(${e.application_avatar})`}}>
      </div>
      <h4>
        {e.application_workplace_name}
      </h4>
      <div className='applications--desc-container'>
      <h2 className={`horizontal-slide-left-animation ${preview ? 'horizontal-slide-left-animation-off' : 'horizontal-slide-left-animation-on'}`} id={`application-${i}-title-slide-left`}>
        {e.application_title}
      </h2>
      <div className="thin-white-bar"></div>
      <h4 className={`applications--desc ${preview ? 'horizontal-slide-right-animation-off' : 'horizontal-slide-right-animation-on'}`} id={`application-${i}-desc-slide-right`}>
        {
          e.application_desc
        }
      </h4>
      </div>
      <div className="applications--button-container">
        {e.application_button_1_title &&
                <div className="applications--screenshots-button" onClick={() => window.open(e.application_button_1_url, '_blank', 'noopener,noreferrer')}>
                {e.application_button_1_title}
              </div>
        }
                {e.application_button_2_title &&
        <div className="applications--demo-button" onClick={() => window.open(e.application_button_2_url, '_blank', 'noopener,noreferrer')}>
        {e.application_button_2_title}
      </div>
        }


      </div>
    </div>
  </div>
  )
})}
    </Col>
    <Col xs={sideWidths}>
    </Col>
    <div style={{marginBottom: '150px'}} />
  </Row>
  )
}