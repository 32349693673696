import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css'

export default function LandingHeader() {

  const [menu, openMenu] = useState(true)

  return (
    <div className='landing-header-container'>
  <div>
    <Link to='/'>
    <div className='devhorizon-logo-white'>
    
    </div>
    </Link>
  </div>
      <div className='landing-menu-container'>
      <Link to='/'>
        <div className='landing-menu-item'>Home</div>
        </Link>
      <Link to='/web-developer-portfolio/builder/horizon'>
        <div className='landing-menu-item'>Portfolio Builder</div>
        </Link>
        <Link to='/web-developer-portfolio/templates'>
        <div className='landing-menu-item'>Templates</div>
        </Link>
        <Link to='/web-developer-portfolio/pricing'>
        <div className='landing-menu-item'>Pricing</div>
          </Link>
          <Link to='/aboutus'>
        <div className='landing-menu-item'>About</div>
          </Link>
      </div>
      <div className='landing-mobile-menu-burger-container' onClick={() => openMenu(!menu)}>
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
        <div className='landing-mobile-menu-burger-slice' />
      </div>
      {menu ? null :
        <div className='landing-mobile-menu'>
                    <Link to='/'>
          <div className='landing-mobile-menu-option'>
            Home
          </div>
          </Link>
          <Link to='/web-developer-portfolio/builder/horizon'>
          <div className='landing-mobile-menu-option'>
            Portfolio Builder
          </div>
          </Link>
          <Link to='/web-developer-portfolio/templates'>
          <div className='landing-mobile-menu-option'>
            Templates
          </div>
            </Link>
            <Link to='/web-developer-portfolio/pricing'>
            <div className='landing-mobile-menu-option'>
            Pricing
          </div>
              </Link>
              <Link to='/aboutus'>
          <div className='landing-mobile-menu-option'>
            About
          </div>
          </Link>
        </div>}
    </div>

  )
}