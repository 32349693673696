import { Col, Form, Row, Card } from 'react-bootstrap';
import { TextField } from '@mui/material';

export default function IntroInput({ config, changeConfig, changeText, changeBackgrounds }) {
  return (
    <Card style={{ paddingBottom: '150px' }} className='input-flash'>
      <Card.Header>
        Edit Intro
      </Card.Header>
      <Card.Body>
      <Row>
        <Col xs={6}>
          <div className='config-input-container mb-3'>
            <TextField
              label="First Name"
              style={{ width: '100%' }}
              value={config.text.header_first_name}
              onChange={(e) =>
                changeText({
                  header_first_name: e.target.value,
                  intro_first_name: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col xs={6}>
          <div className='config-input-container mb-3'>
            <TextField
              style={{ width: '100%' }}
              label='Last Name'
              value={config.text.header_last_name}
              onChange={(e) =>
                changeText({
                  header_last_name: e.target.value,
                  intro_last_name: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <div className='config-input-container mb-3'>
        <TextField
          label='Avatar Image URL'
          style={{ width: '100%' }}
          value={config.backgrounds.avatar_image_url}
          onChange={(e) => changeBackgrounds({ avatar_image_url: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          style={{ width: '100%' }}
          label='Title #1'
          value={config.text.intro_title_1}
          onChange={(e) => changeText({ intro_title_1: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Title #2'
          style={{ width: '100%' }}
          value={config.text.intro_title_2}
          onChange={(e) => changeText({ intro_title_2: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Title #3'
          style={{ width: '100%' }}
          value={config.text.intro_title_3}
          onChange={(e) => changeText({ intro_title_3: e.target.value })}
        />
      </div>
      <Row className='mt-3 mb-3'>
        <Form.Label className='d-flex justify-content-start'>
          Header Labels
        </Form.Label>
      </Row>
      <Row>
        <Col xs={6}>
        <div className='config-input-container mb-3'>
        <TextField
                  style={{ width: '100%' }}
          label='Header Title'
          value={config.text.header_position}
          onChange={(e) => changeText({ header_position: e.target.value })}
        />
      </div>
        </Col>
        <Col xs={6}>
          <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='Skills Title'
              value={config.text.header_skills_title}
              onChange={(e) =>
                changeText({
                  header_skills_title: e.target.value,
                  mobile_menu_skills_title: e.target.value,
                  banner_skills_title: e.target.value
                })
              }
            />
          </div>
        </Col>
      </Row>


      <Row>
        <Col xs={6}>
          <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='Experience Title'
              value={config.text.header_experience_title}
              onChange={(e) =>
                changeText({
                  header_experience_title: e.target.value,
                  mobile_menu_experience_title: e.target.value,
                  banner_experience_title: e.target.value
                })
              }
            />
          </div>
        </Col>
        <Col xs={6}>
        <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='Applications Title'
              value={config.text.header_applications_title}
              onChange={(e) =>
                changeText({
                  header_applications_title: e.target.value,
                  mobile_menu_applications_title: e.target.value,
                  banner_applications_title: e.target.value
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
        <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='Education Title'
              value={config.text.header_education_title}
              onChange={(e) =>
                changeText({
                  header_education_title: e.target.value,
                  mobile_menu_education_title: e.target.value,
                  banner_education_title: e.target.value
                })
              }
            />
          </div>
        </Col>
        <Col xs={6}>
        <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='Contact Title'
              value={config.text.header_contact_title}
              onChange={(e) =>
                changeText({
                  header_contact_title: e.target.value,
                  mobile_menu_contact_title: e.target.value,
                  banner_contact_title: e.target.value
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <Form.Label className='d-flex justify-content-start'>
          About Me
        </Form.Label>
        </Row>
        <div className='config-input-container mb-3'>
            <TextField
                      style={{ width: '100%' }}
              label='About Me Title'
              value={config.text.about_me_title}
              onChange={e => changeText({about_me_title: e.target.value})}
            />
          </div>
        <div style={{ width: '100%'}}>
                            <Form.Control
                              as='textarea'
                              value={config.text.about_me}
                              rows={5}
                              placeholder='Write Something About Yourself'
                              onChange={e => changeText({about_me: e.target.value})}
                            />
                          </div>
      <Row className='mt-3 mb-3'>
        <Form.Label className='d-flex justify-content-start'>
          Background Images
        </Form.Label>
      </Row>
      <div className='config-input-container mb-3'>
        <TextField
          label='Background Image #1 URL'
          style={{ width: '100%' }}
          value={config.backgrounds.intro_background_image_1}
          onChange={(e) => changeBackgrounds({ intro_background_image_1: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Background Image #2 URL'
          style={{ width: '100%' }}
          value={config.backgrounds.intro_background_image_2}
          onChange={(e) => changeBackgrounds({ intro_background_image_2: e.target.value })}
        />
      </div>
      <div className='config-input-container mb-3'>
        <TextField
          label='Background Image #3 URL'
          style={{ width: '100%' }}
          value={config.backgrounds.intro_background_image_3}
          onChange={(e) => changeBackgrounds({ intro_background_image_3: e.target.value })}
        />
      </div>
      </Card.Body>
    </Card>
  );
}
