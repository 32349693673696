import React, {useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './style.css'

export default function LaptopView() {

  const [tab, setTab] = useState('intro')
  const [laptopClass, setLaptopClass] = useState('laptop-1')
  const [mobileTab, setMobileTab] = useState(1)

  const updateTab = (tab, num) => {
    setTab(tab)
    setMobileTab(num)
    setLaptopClass(`laptop-${num}`)
  }

  const updateMobileTab = (which) => {

    const theTabs = ['intro', 'skills', 'exp', 'app', 'edu']

    if (which === 'plus' && mobileTab < 5) {
      setMobileTab(mobileTab + 1)
      updateTab(theTabs[mobileTab], mobileTab + 1)
    } else if (which === 'minus' && mobileTab > 1) {
      setMobileTab(mobileTab - 1)
      updateTab(theTabs[mobileTab - 2], mobileTab - 1)
    }
  }

  return (
    <div>
      <div className='secret-image-1' />
      <div className='secret-image-2' />
      <div className='secret-image-3' />
      <div className='secret-image-4' />
      <div className='secret-image-5' />
    <Row className='nopadding'>
    <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
    <Col xs={10} md={10} lg={8} xl={6} className='nopadding'>
      <div className='laptop-view-container'>
      <div className='laptop-header-text'>
        Your tech career. Visualized.
      </div>
    <div className={laptopClass}>

    </div>

      </div>
    </Col>
    <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
  </Row>

  <Row className='nopadding'>
  <Col xs={1} lg={1} xl={2} md={0} className='nopadding' />
  <Col xs={10} lg={10} xl={8} md={12} className='nopadding'>
  <div className='laptop-menu-container'>
     <div className={'laptop-item-inactive'} onClick={() => updateTab('intro', 1)}>
      <div className={tab === 'intro' ? 'active-ball' : 'inactive-ball'}>
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={tab === 'intro' ? 'white' : 'rgb(20,20,20)'} class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
       </svg>
      </div>
      Intro
     </div>
     <div className={'laptop-item-inactive'} onClick={() => updateTab('skills', 2)}>
      <div className={tab === 'skills' ? 'active-ball' : 'inactive-ball'}>
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={tab === 'skills' ? 'white' : 'rgb(20,20,20)'} class="bi bi-terminal" viewBox="0 0 16 16">
        <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9zM3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z" />
        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12z" />
       </svg>
      </div>
      Skills
     </div>
     <div className={'laptop-item-inactive'} onClick={() => updateTab('exp', 3)}>
      <div className={tab === 'exp' ? 'active-ball' : 'inactive-ball'}>
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={tab === 'exp' ? 'white' : 'rgb(20,20,20)'} class="bi bi-briefcase" viewBox="0 0 16 16">
        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
       </svg>
      </div>
      Experience
     </div>
     <div className={'laptop-item-inactive'} onClick={() => updateTab('app', 4)}>
      <div className={tab === 'app' ? 'active-ball' : 'inactive-ball'}>
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={tab === 'app' ? 'white' : 'rgb(20,20,20)'} class="bi bi-window-stack" viewBox="0 0 16 16">
        <path d="M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
        <path d="M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10ZM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1Zm1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8H3Zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2h12Z" />
       </svg>
      </div>
      Applications
     </div>
     <div className={'laptop-item-inactive'} onClick={() => updateTab('edu', 5)}>
      <div className={tab === 'edu' ? 'active-ball' : 'inactive-ball'}>
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={tab === 'edu' ? 'white' : 'rgb(20,20,20)'} class="bi bi-mortarboard" viewBox="0 0 16 16">
        <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
        <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
       </svg>
      </div>
      Education
     </div>
    </div>
    </Col>
  <Col xs={1} lg={1} xl={2} md={0} className='nopadding' />
  </Row>


  <Row className='nopadding'>
  <Col xs={1} lg={2} className='nopadding' />
  <Col xs={10} lg={8} className='nopadding' >

  <div className='laptop-mobile-menu-container'>

  <div className={'laptop-item-inactive'}>
      <div className='inactive-ball' onClick={() => updateMobileTab('minus')}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={mobileTab > 1 ? 'white' : 'rgb(20,20,20)'} class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
      </div>
      <br />
     </div>

     {mobileTab === 1 &&
       <div className={'laptop-item-inactive'}>
       <div className='active-ball'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} class="bi bi-person-circle" viewBox="0 0 16 16">
         <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
         <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg>
       </div>
       <div style={{fontSize: '24px'}}>
       Intro
       </div>
      </div>
     }

{mobileTab === 2 &&
       <div className={'laptop-item-inactive'}>
       <div className='active-ball'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9zM3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z" />
        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12z" />
        </svg>
       </div>
       <div style={{fontSize: '24px'}}>
       Skills
       </div>
      </div>
     }

{mobileTab === 3 &&
       <div className={'laptop-item-inactive'}>
       <div className='active-ball'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
        </svg>
       </div>
       <div style={{fontSize: '24px'}}>
       Experience
       </div>
      </div>
     }

{mobileTab === 4 &&
       <div className={'laptop-item-inactive'}>
       <div className='active-ball'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
        <path d="M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10ZM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1Zm1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8H3Zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2h12Z" />
        </svg>
       </div>
       <div style={{fontSize: '24px'}}>
       Applications
       </div>
      </div>
     }

{mobileTab === 5 &&
       <div className={'laptop-item-inactive'}>
       <div className='active-ball'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
        <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
        </svg>
       </div>
       <div style={{fontSize: '24px'}}>
       Education
       </div>
      </div>
     }

     <div className={'laptop-item-inactive'}>
     <div className='inactive-ball' onClick={() => updateMobileTab('plus')}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={mobileTab < 5 ? 'white' : 'rgb(20,20,20)'} class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
      </div>
      <br />
     </div>
    </div> 

      <div className='laptop-tab-desc-container'>
      <div className='laptop-tab-desc'>
        <div>
        Devhorizons is a website builder created specifically for web developers and software engineers seeking to showcase their skills with a stunning portfolio website.
        </div>
        <div style={{marginTop: '15px'}}>
        Our <Link style={{ color: '#3366CC' }} to='/web-developer-portfolio/builder/horizon'>Portfolio Builder</Link> empowers you to effortlessly create a professional online presence in just minutes, featuring sleek animations and meticulous design.
        </div>
        <div style={{marginTop: '15px'}}>
        In the competitive world of web development and software engineering, a captivating portfolio website is your passport to opportunities. Crafting a professional website doesn’t have to be complex. Devhorizons simplifies the process, ensuring that even those without extensive design skills can present themselves with sophistication and flair.
        </div>
      </div>
      </div>
      <div className='laptop-call-to-action-container'>
        <Link to='/web-developer-portfolio/builder/horizon'>
        <Button style={{margin: 'auto'}} variant='primary'>Start Building</Button>
        </Link>
      </div>



      
    </Col>
  <Col xs={1} lg={2} className='nopadding' />



  </Row>
    </div>
  )
}