import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import TypeWriter1 from '../../components/TypeWriter1';
import TypeWriter2 from '../../components/TypeWriter2';
import TypeWriter3 from '../../components/TypeWriter3';
import LandingDesc1 from '../../components/LandingDesc1';
import LandingDesc2 from '../../components/LandingDesc2';
import LandingDesc3 from '../../components/LandingDesc3';
import LandingBuilderView from '../../components/LandingBuilderView';
import './style.css';

import LandingHeader from '../../components/LandingHeader';
const LandingIntro = () => {

  const typewriters = [TypeWriter1, TypeWriter2, TypeWriter3];
  const descs = [LandingDesc1, LandingDesc2, LandingDesc3];
  const [currentTypewriterIndex, setCurrentTypewriterIndex] = useState(0);
  const [currentDescIndex, setCurrentDescIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTypewriterIndex((prevIndex) => (prevIndex + 1) % typewriters.length);
    }, 7000); 

    const intervalDescId = setInterval(() => {
      setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descs.length);
    }, 7000); 

    // Clean up the interval on Typewriter unmount

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalDescId);
    }
  }, [typewriters.length, descs.length]);

  const CurrentTypewriter = typewriters[currentTypewriterIndex];
  const CurrentDesc = descs[currentDescIndex];

  return (
<div>
            
            <Row className='landing-row nopadding'>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
        <Col xs={10} md={10} lg={8} xl={6} className='landing-middle-column nopadding'>
          <div className='shape-background' />
    <CurrentTypewriter />
          <div className='landing-desc-container' style={{fontSize: '18px'}}>
          <div className='landing-desc-bar' />
          <CurrentDesc />
          
            </div>
        </Col>
        <Col xs={1} md={1} lg={2} xl={3} className='nopadding' />
      </Row>
</div>
      

  );
};

export default LandingIntro;
